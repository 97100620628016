export const getBrowserOsInfo = {
	options: [],
	header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
	dataos: [
		{ name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
		{ name: 'Windows', value: 'Win', version: 'NT' },
		{ name: 'iPhone', value: 'iPhone', version: 'OS' },
		{ name: 'iPad', value: 'iPad', version: 'OS' },
		{ name: 'Kindle', value: 'Silk', version: 'Silk' },
		{ name: 'Android', value: 'Android', version: 'Android' },
		{ name: 'PlayBook', value: 'PlayBook', version: 'OS' },
		{ name: 'BlackBerry', value: 'BlackBerry', version: '/' },
		{ name: 'Macintosh', value: 'Mac', version: 'OS X' },
		{ name: 'Linux', value: 'Linux', version: 'rv' },
		{ name: 'Palm', value: 'Palm', version: 'PalmOS' },
	],
	databrowser: [
		{ name: 'Chrome', value: 'Chrome', version: 'Chrome' },
		{ name: 'Firefox', value: 'Firefox', version: 'Firefox' },
		{ name: 'Safari', value: 'Safari', version: 'Version' },
		{ name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
		{ name: 'Opera', value: 'Opera', version: 'Opera' },
		{ name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
		{ name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
	],
	init: function () {
		var agent = this.header.join(' ');
		var os = this.matchItem(agent, this.dataos);
		var browser = this.matchItem(agent, this.databrowser);

		return {
			os: os,
			browser: browser,
			language: navigator.language,
			userAgent: navigator.userAgent,
		};
	},
	matchItem: function (string, data) {
		var i = 0;
		var j = 0;
		var html = '';
		var regex;
		var regexv;
		var match;
		var matches;
		var version;

		for (i = 0; i < data.length; i += 1) {
			regex = new RegExp(data[i].value, 'i');
			match = regex.test(string);
			if (match) {
				regexv = new RegExp(`${data[i].version }[- /:;]([\\d._]+)`, 'i');
				matches = string.match(regexv);
				version = '';
				if (matches) { if (matches[1]) { matches = matches[1]; } }
				if (matches) {
					matches = matches.split(/[._]+/);
					for (j = 0; j < matches.length; j += 1) {
						if (j === 0) {
							version += `${matches[j] }.`;
						} else {
							version += matches[j];
						}
					}
				} else {
					version = '0';
				}
				return {
					name: data[i].name,
					version: parseFloat(version),
				};
			}
		}
		return { name: 'unknown', version: 0 };
	},
};

export const getUserAgent = () => {
	return navigator.userAgent;
};

export const sendClientToken = ({
	clientToken,
	baseUrl,
	acui,
	userKey,
	mcid,
	gaid,
	language,
	osName,
	osVer,
	browserName,
	browserVer,
	siteName = 'MH',
	country,
	newLanguage,
}) => {
	var data = {
		'acui': acui,
		'ukey': userKey,
		'ctok': clientToken,
		'os': osName,
		'osv': osVer,
		'osl': language,
		'manf': browserName,
		'manfv': browserVer,
		'ecid': mcid,
		'country': country,
		'language': newLanguage,
	};

	const webRegisterAndroidPath = siteName === 'firefly' ? '/mab/webregisterAndroidFireFly.jssp' : '/mab/webregisterAndroid.jssp';

	var options = {
		url: `${baseUrl}${webRegisterAndroidPath}`,
		method: 'POST',
		data: data,
	};

	return new Promise(function (resolve, reject) {
		var xhr = new XMLHttpRequest();
		xhr.open(options.method, options.url);
		xhr.setRequestHeader('Content-Type', 'text/plain');
		xhr.send(JSON.stringify(options.data));
		xhr.onload = function () {
			resolve(xhr.responseText);
		};
		xhr.onerror = function () {
			reject(xhr.statusText);
		};
	});
};

export const getECID = () => {

	return window._satellite?.getVisitorId()?.getMarketingCloudVisitorID() ?? '';
};

export const isTokenSentToServer = () => {
	return window.localStorage.getItem('sentToServer') === '1';
};

export const setTokenSentToServer = (sent) => {
	window.localStorage.setItem('sentToServer', sent ? '1' : '0');
};

export const isUserInfoSentToServer = () => {
	return window.localStorage.getItem('sentUserInfoToServer') === '1';
};

export const setUserInfoSentToServer = (sent) => {
	window.localStorage.setItem('sentUserInfoToServer', sent ? '1' : '0');
};

export const setEnrichIdSentToServer = (enrichId) => {
	window.localStorage.setItem('enrichIdSentToServer', enrichId ? '1' : '0');
};

export const isSameToken = (latestToken) => {
	return window.localStorage.getItem('tokenFCM') === latestToken;
};

export const setTokenFCM = (token) => {
	window.localStorage.setItem('tokenFCM', token);
};

export const getGAID = () => {
	// return typeof google_tag_manager['GTM-5G46XDD'].dataLayer.get("ClientID") != 'undefined' ? google_tag_manager['GTM-5G46XDD'].dataLayer.get("ClientID") : "null";
	return (document.cookie.match(/_ga=(.+?);/) !== null) ? document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join('.') : '';
};

export const parseJwt = (token) => {
	try {
		var base64Url = token.split('.')[1];
		var base64 = decodeURIComponent(atob(base64Url).split('').map(function (c) {
			return `%${ (`00${ c.charCodeAt(0).toString(16)}`).slice(-2)}`;
		}).join(''));
		return JSON.parse(base64);
	} catch (err) {
		return null;
	}
};

export const isUserLoggedIn = () => {
	var loginTokenCookieVal_1 = document.cookie.match('(^|;)\\s*sapphire\\s*=\\s*([^;]+)');
	var sapphireCookieExist = false;
	
	if (loginTokenCookieVal_1 !== null) {
		sapphireCookieExist = true; // find login status here
	}

	return sapphireCookieExist;
};

export const isEnrichIdSentToServer = () => {
	var idSent = true;

	if (isUserLoggedIn()) {
		if (window.localStorage.getItem('enrichIdSentToServer') === '1') idSent = true;
		else idSent = false;
	} else {
		window.localStorage.setItem('enrichIdSentToServer', '0');
		idSent = false;
	}

	return idSent;
};

export const isHexDecimal = (num) => {
	// eslint-disable-next-line no-bitwise
	const value = parseInt(num >>> 0).toString(16).toLowerCase();
	return value === '0';
};

export const decimalToHexDecimal = (num) => {
	// eslint-disable-next-line no-bitwise
	return parseInt(num >>> 0).toString(16).toLowerCase();
};

export const sendAdobeCampaignTrack = ({ accBaseUrl, mId, dId, tcode }) => {
	const mIdValue = isHexDecimal(mId) ? mId : decimalToHexDecimal(mId);
	const dIdValue = isHexDecimal(dId) ? dId : decimalToHexDecimal(dId);
	const trackingUrl = `${accBaseUrl}/r/?id=h${mIdValue},${dIdValue},${tcode}`;

	fetch(trackingUrl)
		.then(
			function (response) {
				if (response.status !== 200) {
					console.log(`sendAdobeCampaignTrack: ${response.status}`);
					return;
				}
				
				// response.json()
				// 	.then(function (data) { console.log(`sendAdobeCampaignTrack then: ${ data}`); })
				// 	.catch(function (err) { console.log(`sendAdobeCampaignTrack catch: ${ err}`); });
			}
		)
		.catch(function (err) { console.log(`sendAdobeCampaignTrack outer catch: ${ err}`); });
};

export const showMessageNotification = ({ payload, receiveNotificationCallback, clickNotificationCallback }) => {

	if (payload.data) {
	
		var mid = payload.data._mId || '';
		var did = payload.data._dId || '';
		
		console.log(`IDS: ${mid}---${did}`);
		
		const notificationTitle = payload.data.title;

		const notificationOptions = {
			body: payload.data._msg,
			icon: payload.data.icon,
			image: payload.data.image,
			badge: payload.data.badge,
			data: {
				click_action: payload.data.click_action,
				_mid: mid,
				_did: did,
			},
		};

		const notification = new Notification(notificationTitle, notificationOptions);

		receiveNotificationCallback(mid, did);

		notification.onclick = (event) => {
			event.preventDefault();
			clickNotificationCallback(mid, did);
			window.open(payload.data.click_action, '_blank');
			notification.close();
		};
	}
};