<script>
export default {
	name: 'SubTabBookFlight',
};
</script>

<script setup>
import ToggleSwitch from '~/components/form/ToggleSwitch.vue';
import Tooltip from '~/components/Tooltip.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import TextField from '~/components/form/TextField.vue';
import PassengerAndCabinClass from '~/components/BookingWidget/PassengerAndCabinClass.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import yup from 'mh-yup';
import dayjs from 'dayjs';
import { isMobileViewport, isXXXL } from '~/logic/composables/breakpoints.js';
import TransitionHeight from '~/components/transition/TransitionHeight.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useRouteHash } from '~/logic/composables/useRouteHash.js';
import { useEventBus } from '@vueuse/core';
import { useBSTOverlayInterimData } from '~composables/booking-widget/useBSTOverlayInterimData.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { useIsCountryVariant, useSyncPassengerDetails } from '~/logic/composables/booking-widget/usePassengers.js';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { getCookie } from '~/logic/helpers/cookies.js';
import { useLowFarePriceList } from '~composables/booking-widget/useLowFarePriceList.js';
import { isUrlMatchCurrentHostname, formatNumberWithAbbreviation } from '~/logic/helpers/string.js';
import { sleep, waitFor } from '~/logic/helpers/utils.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import { pushAA_searchError } from '~/logic/adobe-analytic.js';
import { useIsRTL } from '~/logic/helpers/is-rtl.js';
import Checkbox from '~/components/form/Checkbox.vue';
import AppSpinner from '~/components/AppSpinner.vue';
import { Teleport } from 'vue';
import useDisableScroll from '~composables/useDisableScroll.js';

const props = defineProps({
	hideTodayDateIndicator: { type: Boolean, default: false },
	subHeaderTabDescription: { type: String, default: '' },
	subHeaderTabTooltipBody: { type: String, default: '' },
	subHeaderTabTooltipLabel: { type: String, default: '' },
	topNote: { type: String, default: '' },
	note: { type: String, default: '' },
	searchFlightCTAText: { type: String, default: i18nGlobal.t('Search flight') },
	disableGeolocation: { type: Boolean, default: false },

	ondSelection: { type: Object, default: null },
	additionalONDFilter: { type: String, default: null },
	datePicker: { type: Object, default: null },
	passengerSelection: { type: Object, default: null },
	promoCodeLabel: { type: String, default: null },
	promoCodeErrorMessage: { type: String, default: i18nGlobal.t('Conditions not met for promo code to be applied') },
	supersedePromoCode: { type: String, default: null },

	bstNoteTop: { type: String, default: '' },
	bstNoteCTAText: { type: String, default: '' },
	bstNoteCTAURL: { type: String, default: '' },

	subHeaderTooltipCTATextMobileLabel: { type: String, default: '' },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	disableCalendarPickerAnimation: { type: Boolean, default: false },
	passengerSelectionQuestion: { type: String, default: '' },
	flightSearchApiUrl: { type: String, default: '' },
	cabinClassCashList: { type: Array, default: () => [] },
	cabinClassMilesList: { type: Array, default: () => [] },

	componentIdAA: { type: String, default: '' },
	togglePointsApiURL: { type: String, default: '' },

	enableMattaToggleButton: { type: Boolean, default: false },
	mattaToggleButtonLabel: { type: String, default: '' },

	hidePromoCodeField: { type: Boolean, default: false },
	
	enrichAlwaysOn: { type: Boolean, default: false },
	enablePostMattaCampaign: { type: Boolean, default: false },

	enrichAlwaysOnCountriesData: { type: Array, default: () => [] },

	loggedInMessageNote: { type: String, default: '' },
	nonLoggedInMessageNote: { type: String, default: '' },
	enableONDLocaleTranslation: { type: Boolean, default: false },
	countryVariants: { type: Array, default: () => [] },
	payByEnrichPoints: { type: Boolean, default: false },
	tabName: { type: String, default: 'book-flight' },
	activeSubOption: { type: Object, default: null },
	expressBooking: { type: Object, default: null },
	signUpApiUrl: { type: String, default: '' },
});

const emit = defineEmits([
	'save-search-data',
	'show-bst-overlay',
	'form-submission',
]);

const { isRTL } = useIsRTL();
const siteName = window.siteName;

const formWrapper = ref(null);
const rootEl = ref(null);
const passengerAndCabinClassEl = ref(null);
const dateRangePickerEl = ref(null);
const ondPickerEl = ref(null);
const isLoading = ref(null);

const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);

const ondValueFrom = ref(null);
const ondValueTo = ref(null);

const promoCodeValue = ref(null);

const eligibleForBonusSideTrip = ref(false);
const optInBonusSideTrip = ref(false);
const token = ref('');

const isCampaignToggleON = ref(false);

const { pageProperties, isEditorMode } = useGlobalAEMState();

const isExpanded = ref(true);

const enrichAlwaysOnEligibility = ref(false);
const optEnrichAlwaysOnOffer = ref(props.enablePostMattaCampaign);

const isJDTPortal = ref(false);
const isJDTNormalBooking = ref(false);

const country = pageProperties.value.rootCountry;
const language = pageProperties.value.rootLanguage;

const { isSapphireCookiePresent } = useAuthStore();

const capitalizeString = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const showAlwaysOnEnrichNonLoginMsg = computed(() => {
	return props.enrichAlwaysOn && !isSapphireCookiePresent() && props.nonLoggedInMessageNote;
});

const isEligibleForErichAlwaysOn = computed(() => {
	if (
		enrichAlwaysOnEligibility.value &&
		optEnrichAlwaysOnOffer.value
	) {
		return 'true';
	}

	return 'false';
});

const computedCabinClassValue = computed(() => {
	if (!passengerAndCabinClassEl.value) return;
	/*
		The cabin class value from the passenger and cabin class dropdown is 1, 2, 3.
		Economy: 1,
		Business: 2,
		Business Suite: 3.
		While form submission, due to different form has different cabin class value, even though their label(Economy, Business, Business Suite) are the same. 
		require to map to the actual cabin class value, which come from the component config.
	*/
	const index = parseInt(passengerAndCabinClassEl.value.cabinClassValue?.value) - 1;
	
	if (props.payByEnrichPoints) {
		return props.cabinClassMilesList[index].value;
	}
	return props.cabinClassCashList[index].value;
});

const selectedCabinClassAfterDelimiter = computed(() => computedCabinClassValue.value?.split('|')?.at(-1));
const selectedCabinClassBeforeDelimiter = computed(() => computedCabinClassValue.value?.split('|')?.at(0));

const lowFareApiUrl = pageProperties?.value?.lowFareApiUrl;
const isOneWay = ref(false);

// const dateRangePriceData = ref(useLowFarePriceList(ondValueFrom, ondValueTo, dateRangeValueFrom, props.payByEnrichPoints, lowFareApiUrl));
const {
	priceData: dateRangePriceData,
	currency,
	returnTypeDepartureDateAndFare: departureDateAndFare,
} = useLowFarePriceList({
	originRef: ondValueFrom,
	destinationRef: ondValueTo,
	departureDateRef: dateRangeValueFrom,
	paymentTypeRef: ref(props.payByEnrichPoints),
	endpointUrl: lowFareApiUrl,
	isOneWayRef: isOneWay,
});

const handleWatchIsOneWay = (flag) => {
	isOneWay.value = flag;
};

const {
	isLoggedIn,
	isAuthStateReady,
} = useAuthStore();

const { hash, hashQuery } = useRouteHash();
const SAPPHIRE_COOKIE = 'sapphire';
token.value = getCookie(SAPPHIRE_COOKIE) ?? null;
const isProcessingPayEnrichPoint = ref(false);

watch( props.payByEnrichPoints, async (newValue) => {
	await sleep(50);

	const updatedONDListFrom = ondPickerEl.value?.computedOptionsFrom;
	if (ondValueFrom.value && updatedONDListFrom?.length) {
		// Ungroup and combine all OND list option
		const mergeONDListFrom = updatedONDListFrom.flatMap((groupList) => {
			return groupList.options;
		});

		const isItemIncluded = mergeONDListFrom.some((item) => ondValueFrom.value?.airportCode === item.airportCode);
		if (!isItemIncluded) {
			ondValueFrom.value = null;
			ondValueTo.value = null;
		}
	}

	const updatedONDListTo = ondPickerEl.value?.computedOptionsTo;
	if (ondValueTo.value && updatedONDListTo?.length) {
		// Ungroup and combine all OND list option
		const mergeONDListTo = updatedONDListTo.flatMap((groupList) => {
			return groupList.options;
		});

		const isItemIncluded = mergeONDListTo.some((item) => ondValueTo.value?.airportCode === item.airportCode);
		if (!isItemIncluded) ondValueTo.value = null;
	}

	// every time toggle switch need to reset the promo code once, if it has been filled.
	if (promoCodeValue.value) promoCodeValue.value = null;
});

const handleRedirectToLoginAndSignUp = async ({ status }) => {
	/*
	*	@status String parameter: 'signup' | 'login'
	*/

	if (!props.togglePointsApiURL) throw new Error('Failed to get endpoint: togglePointsApiURL');
	if (!props.signUpApiUrl) throw new Error('Failed to get endpoint: signUpApiUrl');
	
	try {
		const endpoint = status === 'signup' ? props.signUpApiUrl : props.togglePointsApiURL;
		const apiResponse = await axios.get(endpoint, {
			params: {
				type: 'points',
				maintab: 'flight-search-tab',
				subtab: 'book-flight',
				locationFrom: ondValueFrom.value?.code,
				locationTo: ondValueTo.value?.code,
				dateDeparture: dateRangeValueFrom.value,
				dateReturn: dateRangeValueTo.value,
				
				cabinClass: passengerAndCabinClassEl.value.cabinClassValue?.value,
				adultsCount: passengerAndCabinClassEl.value.adults.count.value,
				teensCount: passengerAndCabinClassEl.value.teens.count.value,
				childrenCount: passengerAndCabinClassEl.value.children.count.value,
				infantsCount: passengerAndCabinClassEl.value.infants.count.value,
				promoCode: props.supersedePromoCode ?? promoCodeValue.value,

				isPoints: true,
				...(isExpressBooking.value ? { isExpressBooking: true } : null ),
			},
		});
		const url = apiResponse.data;

		window.location.href = url;
	} catch (e) {
		console.error('handleRedirectToLoginAndSignUp, ', 'Unable to fetch api', e);
	}
};

const promoCodeTextField = ref(null);
const isExpressBooking = ref(false);

const handleSubmitValid = async (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);

	if (isExpressBooking.value && !isLoggedIn.value) {
		isExpressBookingPopUpVisible.value = true;
		return;
	}

	isLoading.value = true;

	const searchData = {
		isBSTTab: false,
		
		payByEnrichPoints: props.payByEnrichPoints,
		optInBonusSideTrip: optInBonusSideTrip.value,
		dateRangeValueFrom: dateRangeValueFrom.value,
		dateRangeValueTo: dateRangeValueTo.value,
		isOneWay: !dateRangeValueTo.value,
		ondValueFrom: ondValueFrom.value,
		ondValueTo: ondValueTo.value,
		promoCodeValue: props.supersedePromoCode ?? promoCodeValue.value,
		cabinClassValue: passengerAndCabinClassEl.value.cabinClassValue,
		adultsCount: passengerAndCabinClassEl.value.adults.count.value,
		teensCount: passengerAndCabinClassEl.value.teens.count.value,
		childrenCount: passengerAndCabinClassEl.value.children.count.value,
		infantsCount: passengerAndCabinClassEl.value.infants.count.value,
		userInputPromoCode: promoCodeValue.value,
		
		...(props.enableMattaToggleButton ? { isCUGCampaignPage: props.enableMattaToggleButton, isCampaignToggleON: isCampaignToggleON.value } : null),
		...(isExpressBooking.value ? { isExpressBooking: true, expressBookingSupportingCabinClass: props.expressBooking?.supportedCabinClass } : null ),
	};

	if (searchData.payByEnrichPoints) {
		searchData['activeSubOption'] = props.activeSubOption.text;
		searchData['redeemFlightType'] = props.activeSubOption.value;
	}
	
	emit('save-search-data', searchData);
	emit('form-submission', searchData);
	
	if (optInBonusSideTrip.value) {
		emit('show-bst-overlay');

		return commitDataToBSTOverlay(props.tabName, {
			ondValueFrom: searchData.ondValueFrom,
			ondValueTo: searchData.ondValueTo,
			dateRangeValueFrom: searchData.dateRangeValueFrom,
			dateRangeValueTo: searchData.dateRangeValueTo,
			isOneWay: searchData.isOneWay,
			
			adultsCount: searchData.adultsCount,
			teensCount: searchData.teensCount,
			childrenCount: searchData.childrenCount,
			infantsCount: searchData.infantsCount,
			
			cabinClassValue: searchData.cabinClassValue,
			payByEnrichPoints: false, /* BST not allow for paying with point */
			promoCodeValue: props.supersedePromoCode ?? promoCodeValue.value,
		});

	}

	const data = {
		'departDate1': dayjs(searchData.dateRangeValueFrom).format('YYYYMMDDHHmm'),
		'returnDate1': !searchData.isOneWay && searchData.dateRangeValueTo ? dayjs(searchData.dateRangeValueTo).format('YYYYMMDDHHmm') : '',
		'originCountry': searchData.ondValueFrom.countryName,
		'originAirportCode1': searchData.ondValueFrom.code,
		'destAirportCode1': searchData.ondValueTo.code,
		'flightClass': computedCabinClassValue.value,
		'adultCount': searchData.adultsCount.toString(),
		...(isCountryVariant.value ? { 'teenCount': searchData.teensCount.toString() } : null),
		'childCount': searchData.childrenCount.toString(),
		'infantCount': searchData.infantsCount.toString(),
		'paymentType': searchData.payByEnrichPoints ? 'miles' : 'cash',
		'amal1': searchData.ondValueTo._original.isAmalFlag,
		'promoCode': searchData.promoCodeValue ?? '',
		'regionLanguage': country && language ? `${country}-${capitalizeString(language)}` : '',
		'amcvId': window._satellite?.getVar('ECID') ?? '',
		'teaserCategory': getCookie('TeaserCategory') ?? '',
		'isJdtPortal': isJDTPortal.value,
		'isJdtNormalBooking': isJDTNormalBooking.value,
		...(props.enableMattaToggleButton ? { 'isCUGCampaignPage': searchData.isCUGCampaignPage, 'isCampaignToggleON': searchData.isCampaignToggleON } : null),
		...(props.enrichAlwaysOn ? { 'enrichAlwaysOnEligibility': isEligibleForErichAlwaysOn.value } : null ),
		...(isExpressBooking.value ? { isExpressBooking: true, expressBookingSupportingCabinClass: props.expressBooking?.supportedCabinClass } : null ),
	};

	if (searchData.payByEnrichPoints) {
		data['redeemFlightType'] = searchData.redeemFlightType;
	}

	try {
		
		// 1st api to get response with url and payload for 2nd api
		const apiResponse = await axios({
			method: 'post',
			url: props.flightSearchApiUrl,
			data: data,
		});
		
		const { url, payload } = apiResponse.data;
		
		// 'RAW_DATA' doesn't required, delete it. 
		delete payload['RAW_DATA'];

		// 2nd api need to post with form submission
		// add extra property to payload object
		const generatedData = {};
		const entriesPayload = Object.entries(payload);
		for (let [index, [key, value]] of entriesPayload.entries()) {
			generatedData[index] = key;
		}
		const massageData = { ...generatedData, ...payload };
		const entriesMassageData = Object.entries(massageData);

		// create hidden form and submit
		const method = 'post';
		const form = document.createElement('form');
		form.setAttribute('method', method);
		form.setAttribute('action', url);
		
		for (let [index, [key, value]] of entriesMassageData.entries()) {
			// console.log(`apiResponse => ${index}: ${key}: ${value}`);
			const _key = parseInt(key);
			let hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', value);
			form.appendChild(hiddenField);
		}
		document.body.appendChild(form);
		isLoading.value = false;
		form.submit();

	} catch (err) {
		console.error('handleSubmitValid, ', 'Unable to fetch api', JSON.stringify(err));

		isLoading.value = false;

		// from BE if status code === 400, it means promo code issue
		const { statusCode, message } = err.response.data;
		if (statusCode === 400) {
			// promo code error
			promoCodeTextField.value.setErrors(i18nGlobal.t(message));
			triggerAA_searchError({
				errorMsg: i18nGlobal.t(message),
			});
		}
	}
};
const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};


const handleFormFocusWithin = () => {
	if (isSticked.value) return; // do not mess with this when in sticky mode
	isExpanded.value = true;
};
const handleFormBlurWithin = () => {
	//
};

const isBtnSubmitDisabled = computed(() => {
	const form = formWrapper.value;
	if (!form) return true;

	const isOneWay = dateRangePickerEl.value?.isOneWay;
	return (
		!form.values.locationFrom ||
		!form.values.locationTo ||
		!form.values.dateDeparture ||
		(!isOneWay && !form.values.dateReturn) ||
		Object.keys(form.errors).length !== 0
	);
});


const bookingWidgetBus = useEventBus('booking-widget');
const isSticked = ref(false);

bookingWidgetBus.on((event, payload) => {
	switch (event) {
		case 'sticky:sticked': {
			isSticked.value = true;
			break;
		}
		case 'sticky:unsticked': {
			isSticked.value = false;
			break;
		}
		default: {
			// console.log(`Unknown event: "${event}". Ignoring it.`);
			break;
		}
	}
});

const handleExpandTransitionEnd = () => {
	// no need to call this anymore
	// bookingWidgetBus.emit('refresh-scroll-trigger');
};
/* const handleBonusSideTripBannerChanged = () => {
	bookingWidgetBus.emit('refresh-scroll-trigger');
}; */


const areDatesValid = (from, to = null) => {
	const today = dayjs();
	const dayJsMaxAllowedDaysAhead = today.add(datePickerMaxAllowedFutureDays.value, 'day');
	const dayJsFrom = dayjs(from);
	const dayJsTo = dayjs(to);
	
	if (dayJsFrom.isValid() === false || (to && dayJsTo.isValid() === false)) return false;
	
	if (dayJsFrom.isBefore(today, 'day')) {
		return false;
	}
	if (to && dayJsTo.isAfter(dayJsMaxAllowedDaysAhead, 'day')) {
		return false;
	}
	return true;
};

const validateAndRestoreDates = ({ from, to = null, isOneWay = false } = {}) => {
	if (areDatesValid(from, (isOneWay ? null : to))) {
		// valid date
		dateRangeValueFrom.value = dayjs(from).format('YYYY-MM-DD');
		dateRangeValueTo.value = (to && dayjs(to).format('YYYY-MM-DD')) ?? null;
	} else {
		// invalid date
		dateRangeValueFrom.value = null;
		dateRangeValueTo.value = null;
		
		// have to reset the validation here, so that the 'setTouched()' and 'setErrors()' inside the nextTick() will work
		dateRangePickerEl.value?.inputElFrom?.resetField();
		
		nextTick().then(() => {
			try {
				dateRangePickerEl.value?.inputElFrom?.setTouched(true);
				dateRangePickerEl.value?.inputElFrom?.setErrors(i18nGlobal.t('The selected dates are no longer valid. Please reselect.'));
			} catch {
				// do nothing
			}
		});
	}
};

const restorePastSearch = (searchData) => {
	ondValueFrom.value = searchData.ondValueFrom;
	ondValueTo.value = searchData.ondValueTo;
	
	validateAndRestoreDates({
		from: searchData.dateRangeValueFrom,
		to: searchData.dateRangeValueTo || null,
		isOneWay: !!searchData.isOneWay,
	});
	
	promoCodeValue.value = searchData.userInputPromoCode;
	optInBonusSideTrip.value = searchData.optInBonusSideTrip;
	passengerAndCabinClassEl.value?.setValues({
		adults: searchData.adultsCount,
		teens: searchData.teensCount,
		children: searchData.childrenCount,
		infants: searchData.infantsCount,
		cabinClass: searchData.cabinClassValue,
	});
	
	// set one-way
	dateRangePickerEl.value.handleSetIsOneWay(!!searchData.isOneWay);
};

const { commitDataToBSTOverlay, registerNotifier } = useBSTOverlayInterimData();

registerNotifier('book-flight', async (newData) => {
	ondValueFrom.value = newData.ondValueFrom;
	ondValueTo.value = newData.ondValueTo;
	
	dateRangeValueFrom.value = newData.dateRangeValueFrom;
	dateRangeValueTo.value = newData.dateRangeValueTo;
	
	// const wait = waitFor(() => !!passengerAndCabinClassEl.value, 400, 30); // <-- 400ms * 30 = 12 seconds
	// await wait.start();
	
	passengerAndCabinClassEl.value?.setValues({
		adults: newData.adultsCount,
		teens: newData.teensCount,
		children: newData.childrenCount,
		infants: newData.infantsCount,
		cabinClass: newData.cabinClassValue,
	});
	
	dateRangePickerEl.value.handleSetIsOneWay(!!newData.isOneWay);
});


const defaultMinDate = 0;
const defaultMaxDate = 361;
const datePickerMinAllowedFutureDays = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.departureDateConfig || typeof datePickerData?.departureDateConfig !== 'number') return defaultMinDate;
	return datePickerData?.departureDateConfig;
});

const datePickerMaxAllowedFutureDays = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.returnDateConfig || typeof datePickerData?.returnDateConfig !== 'number') return defaultMaxDate;
	return datePickerData?.returnDateConfig;
});

const formattedDisabledDateList = computed(() => {
	let disabledDates = null;
	const disableSpecificDateList = props.datePicker?.disableSpecificDateList;

	if (disableSpecificDateList?.length) {
		disabledDates = [];

		disableSpecificDateList.forEach((date) => {
			if (!date.disableDate) return;
			disabledDates.push(date.disableDate);
		});
	}
	return disabledDates;
});

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'flight-search-tab',
	subTabName: props.tabName,
});

registerQueryChange(async (query) => {
	if (!query) return;
	
	// ondValueFrom.value = query.locationFrom ? await getONDValueByAirportCode(query.locationFrom, { direction: 'from' }) : null;
	// ondValueTo.value = query.locationTo ? await getONDValueByAirportCode(query.locationTo, { direction: 'to' }) : null;
	
	ondValueFrom.value = query.locationFrom;
	ondValueTo.value = query.locationTo;
	
	nextTick().then(async () => {
		await ondPickerEl.value?.updateFromValue();
		ondPickerEl.value?.updateToValue();
	});
	
	validateAndRestoreDates({
		from: query.dateDeparture,
		to: query.dateReturn || null,
		isOneWay: (query.isOneWay === 'true'),
	});
	
	promoCodeValue.value = query.promoCode ?? null;
	
	await nextTick();

	passengerAndCabinClassEl.value?.setValues({
		...(query.adultsCount ? { adults: parseInt(query.adultsCount) } : null),
		...(query.teensCount ? { teens: parseInt(query.teensCount) } : null),
		...(query.childrenCount ? { children: parseInt(query.childrenCount) } : null),
		...(query.infantsCount ? { infants: parseInt(query.infantsCount) } : null),
		...(query.cabinClass ? { cabinClass: parseInt(query.cabinClass) } : null),
	});

	optInBonusSideTrip.value = query.bst ?? false;

	if (query.isPoints === 'true') {
		setEnrichPointStatus(true);
	}

	if (query.isOneWay === 'true') {
		dateRangePickerEl.value.handleSetIsOneWay(!!query.isOneWay);
	}
});

const updateLocationFromTo = async (payload) => {
	const { locationFrom, locationTo } = payload;

	// wait for ONDList generation to proceed to update ONDPicker
	ondPickerEl.value?.toggleGenerateONDList();
	const wait = waitFor(() => (ondPickerEl.value?.computedOptionsFrom && ondPickerEl.value?.computedOptionsTo), 400, 30); // <-- 400ms * 30 = 12 seconds
	await wait.start();
	
	// if (locationFrom) ondValueFrom.value = await getONDValueByAirportCode(locationFrom, { direction: 'from' });
	// if (locationTo) ondValueTo.value = await getONDValueByAirportCode(locationTo, { direction: 'to' });

	if (locationFrom) ondValueFrom.value = locationFrom;
	if (locationTo) ondValueTo.value = locationTo;

	nextTick().then(async () => {
		await ondPickerEl.value?.updateFromValue();
		ondPickerEl.value?.updateToValue();
	});
};


const sumPrice = ref(0);

watch(dateRangeValueTo, (newValue) => {
	if (
		newValue &&
		ondValueFrom.value &&
		ondValueTo.value &&
		dateRangeValueTo.value &&
		dateRangePriceData.value &&
		dateRangePriceData.value[dateRangeValueTo.value] &&
		departureDateAndFare?.value?.totalFareAmount
	) {
		sumPrice.value = parseInt(departureDateAndFare.value.totalFareAmount) + parseInt(dateRangePriceData.value[dateRangeValueTo.value]);
		return;
	}

	sumPrice.value = 0;
});

const sumPriceWithCurrency = computed(() => {
	if (!currency.value && !sumPrice.value) return '';
	return `${currency.value} ${formatNumberWithAbbreviation(sumPrice.value)}`;
});

const isCountryVariant = ref(false);

const getIsBstEligible = async () => {
	if (
		ondValueFrom.value?.value &&
		ondValueTo.value?.value &&
		dateRangeValueFrom.value &&
		dateRangeValueTo.value &&
		selectedCabinClassAfterDelimiter.value
	) {
		const cFrom = ondValueFrom.value?.value;
		const cTo = ondValueTo.value?.value;
		
		// check eligibility for BST
		return axios.get('/bin/mh/revamp/ondLists?type=bst_validate', { params: {
			ori: cFrom,
			des: cTo,
			cabin: selectedCabinClassAfterDelimiter.value,
			dateFrom: dateRangeValueFrom.value,
			dateTo: dateRangeValueTo.value,
		} }).then((resp) => {
			// check if current pairing is still valid. It is possible that user changes value before API is completed.
			if (ondValueFrom.value?.value === cFrom && ondValueTo.value?.value === cTo) {
				// pairing matches
				if (resp.status === 200 && resp.data && Object.keys(resp.data).length > 0) {
					return true;
				}
			}
		}).catch((reason) => {
			console.log('bst_validate api failed reason = ', reason);
			return false;
		});
	} else {
		return false;
	}
};

watch([ondValueFrom, ondValueTo, dateRangeValueFrom, dateRangeValueTo, computedCabinClassValue], async (newValue) => {
	// side effect for BST banner visibility
	// console.log('watch getIsBstEligible triggered');
	// console.log('ondValueFrom.value?.value = ', ondValueFrom.value?.value);
	// console.log('ondValueTo.value?.value = ', ondValueTo.value?.value);
	// console.log('dateRangeValueFrom.value = ', dateRangeValueFrom.value);
	// console.log('dateRangeValueTo.value = ', dateRangeValueTo.value);
	// console.log('------------');
	
	getIsBstEligible().then((isBstEligible) => {
		eligibleForBonusSideTrip.value = isBstEligible;
	});
});

const getIsEnrichAlwaysEligible = async () => {
	if (
		ondValueFrom.value &&
		ondValueTo.value
	) {
		const cFromCountry = ondValueFrom.value?.countryName;
		const cFromIsRedemption = ondValueFrom.value?.isRedemption;
		const cToIsRedemption = ondValueTo.value?.isRedemption;
		const iscFromInAvalailableList = props.enrichAlwaysOnCountriesData.includes( cFromCountry );
		if (
			cFromIsRedemption &&
			cToIsRedemption &&
			iscFromInAvalailableList
		) {
			return true;
		}
	}

	return false;
};

watch([ondValueFrom, ondValueTo, props.payByEnrichPoints], async (newValue) => {

	if (
		props.enrichAlwaysOn &&
		isSapphireCookiePresent() &&
		props.enrichAlwaysOnCountriesData.length > 0 &&
		!props.payByEnrichPoints
	) {
		enrichAlwaysOnEligibility.value = await getIsEnrichAlwaysEligible();
		return;
	}

	enrichAlwaysOnEligibility.value = false;
});

watch([ondValueFrom, ondValueTo], async (newValue) => {
	// reset teen passenger count, once user select non-uk airport
	isCountryVariant.value = useIsCountryVariant(props.countryVariants, { origin: ondValueFrom.value, destination: ondValueTo.value });
	if (isCountryVariant.value) return;
	passengerAndCabinClassEl.value?.setValues({
		teens: 0,
	});
});

const triggerAA_searchError = ({ errorMsg }) => {
	const errorInfo = {
		errorName: errorMsg,
	};
	const webInteractions = {
		name: props.searchFlightCTAText,
		type: 'exit',
	};
	pushAA_searchError([errorInfo, webInteractions]);
};

useSyncPassengerDetails(passengerAndCabinClassEl);

const focusToDatePicker = (newValue) => {
	if (newValue && !isMobileViewport.value) dateRangePickerEl.value?.inputElFrom?.focus();
};

const groupLabelsOverwrite = computed(() => {
	if (!props.ondSelection) return null;

	return {
		'NEAR_YOU': props.ondSelection?.nearYouLabel,
		'POPULAR_DEST': props.ondSelection?.popularDestinationsLabel,
		'WITH_MH': props.ondSelection?.withMalaysiaAirlinesLabel,
		'CODESHARE': props.ondSelection?.withCodeShareAndPartnerNetworkLabel,
	};
});

const getOndListIdentifier = computed(() => {
	if (props.payByEnrichPoints && props.activeSubOption.value === 'ow-redemption') {
		return 'FLIGHT_SEARCH_OW_REDEMPTION';
	} else if (props.payByEnrichPoints && props.activeSubOption.value === 'malaysia-airlines') {
		return 'FLIGHT_SEARCH_ENRICH';
	} else {
		return 'FLIGHT_SEARCH_CASH';
	}
});

const ExpressBookingPopUpEl = ref(null);
const isExpressBookingPopUpVisible = ref(false);

watch(isExpressBookingPopUpVisible, async (newValue) => {
	await sleep(500); // <-- this is a workaround / hack to force the below 'useDisableScroll' statement to take effect later
	useDisableScroll(isExpressBookingPopUpVisible);
}, { immediate: true });

const dismissOverlayByUser = () => {
	isExpressBookingPopUpVisible.value = false;
};

const isDisabledExpressBooking = computed(() => {
	// enable express booking, while passenger count is 1
	const isOnePassenger = passengerAndCabinClassEl.value?.adults.count.value + passengerAndCabinClassEl.value?.children.count.value + passengerAndCabinClassEl.value?.teens.count.value + passengerAndCabinClassEl.value?.infants.count.value === 1;
	
	// enable express booking, while the selected cabin class is matched with the express booking supported cabin class 
	const eligibleCabinClassList = props.expressBooking?.supportedCabinClass?.replace(/\s/g, '').split(',') || [];
	const hasMatchedCabinClass = eligibleCabinClassList?.includes(selectedCabinClassBeforeDelimiter.value);

	// express booking is not support BST as well
	const isEligibleExpressBooking = isOnePassenger && hasMatchedCabinClass && !optInBonusSideTrip.value;

	if (!isEligibleExpressBooking) isExpressBooking.value = false;
	return !isEligibleExpressBooking;
});

const handleExpressBookingChecked = (event) => {
	isExpressBooking.value = event.target.checked;
};

onMounted(() => {
	const pathName = window.location.pathname;
	isJDTPortal.value = pathName.endsWith('jdt-portal.html');
	isJDTNormalBooking.value = pathName.endsWith('normal-booking.html');
});

defineExpose({
	restorePastSearch,
	updateLocationFromTo,
	isExpanded,
});



</script>

<template>
<div ref="rootEl" class="SubTabBookFlight">
	<FormWrapper
		ref="formWrapper"
		v-slot="{ errors }"
		v-focus-within
		class="-mt-16 lg:mt-0"
		:data-use-theme="siteName"
		tabindex="-1"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
		@focus-within="handleFormFocusWithin"
		@blur-within="handleFormBlurWithin"
	>
		<div>
			<div class="flex">
				<div
					class="toggle-group"
					:class="{
						'mr-auto lg:mr-0': isRTL,
						'ml-auto lg:ml-0': !isRTL,
						'pt-15.5': !isMobileViewport,
					}"
				>
					<ToggleSwitch
						v-if="props.enableMattaToggleButton"
						v-model="isCampaignToggleON"
						:rootAttrs="{
							class: 'mb-8',
						}"
						:ariaLabel="props.mattaToggleButtonLabel"
						name="usingCugPromoCode"
					>
					</ToggleSwitch>
				</div>
			</div>

			<div v-if="props.subHeaderTabDescription" v-html-sanitize="props.subHeaderTabDescription" class="mb-5"></div>

			<Tooltip v-if="props.subHeaderTabTooltipLabel" class="inline-flex mb-5">
				<template #default>
					<div class="flex items-center">
						<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
						{{ props.subHeaderTabTooltipLabel }}
					</div>
				</template>
				<template #mobile-title>
					{{ props.subHeaderTabTooltipLabel }}
				</template>
				<template #tooltip-content>
					<div v-html-sanitize="props.subHeaderTabTooltipBody"></div>
				</template>
				<template #mobile-cta-label>
					{{ props.subHeaderTooltipCTATextMobileLabel }}
				</template>
			</Tooltip>

			<!-- Top notes -->
			<div
				v-if="props.topNote"
				class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl >lg:mt-3 mb-4 >lg:mb-9"
			>
				<icon-fas-circle-info class="fill-primary-blue-base text-base" />
				<div
					v-html-sanitize="props.topNote"
					class="text-sm"
				>
				</div>
			</div>
			
			<div class="flex gap-6 2xl:gap-1 xl:flex-col field-wrapper">
				<div class="w-7/10 xl:w-full component-wrapper">
					<ONDPicker
						ref="ondPickerEl"
						v-model:modelValueFrom="ondValueFrom"
						v-model:modelValueTo="ondValueTo"
						nameFrom="locationFrom"
						nameTo="locationTo"
						:ondListIdentifier="getOndListIdentifier"
						:requiredFrom="true"
						:requiredTo="true"
						:specificOriginList="props.ondSelection?.showSpecificOrigin"
						:specificDestinationList="props.ondSelection?.showSpecificDestination"
						:hideSpecificOriginList="props.ondSelection?.hideSpecificOrigin"
						:hideSpecificDestinationList="props.ondSelection?.hideSpecificDestination"
						:requiredErrorMsgFrom="props.ondSelection?.fromSearchErrorMessage"
						:requiredErrorMsgTo="props.ondSelection?.toSearchErrorMessage"
						:labelTextFrom="props.ondSelection?.fromLabel"
						:labelTextTo="props.ondSelection?.toLabel"
						:noResultsText="props.ondSelection?.noResultMessage"
						:requestGeolocation="!props.disableGeolocation"
						:groupLabelsOverwrite="groupLabelsOverwrite"
						:enableONDLocaleTranslation="props.enableONDLocaleTranslation"
						:additionalONDFilter="props.additionalONDFilter"
						@update:modelValueTo="focusToDatePicker"
					>
						<template #from-picker-mobile-title>
							<span>{{ props.fromQuestionMobile }}</span>
						</template>
						<template #to-picker-mobile-title>
							<span>{{ props.toQuestionMobile }}</span>
						</template>
					</ONDPicker>
				</div>
				<div class="w-4/10 xl:w-full component-wrapper">
					<DateRangePicker
						ref="dateRangePickerEl"
						v-model:modelValueFrom="dateRangeValueFrom"
						v-model:modelValueTo="dateRangeValueTo"
						:priceData="dateRangePriceData"
						:textFieldAttrsFrom="{
							ariaLabel: props.datePicker?.departureDateLabel ?? $t('Depart'),
							placeholder: $t('Select a date'),
						}"
						:textFieldAttrsTo="{
							ariaLabel: props.datePicker?.returnDateLabel ?? $t('Return'),
							placeholder: $t('Select a date'),
						}"
						:minDate="`t+${datePickerMinAllowedFutureDays}d`"
						:maxDate="`t+${datePickerMaxAllowedFutureDays}d`"
						:disabledDates="formattedDisabledDateList"
						minDateFromAfterSelectingTo="t"
						:disablePastDates="true"
						nameFrom="dateDeparture"
						nameTo="dateReturn"
						:requiredFrom="true"
						:requiredTo="true"
						:requiredErrorMsgFrom="props.datePicker?.invalidDateErrorMessage"
						:requiredErrorMsgTo="props.datePicker?.invalidDateErrorMessage"
						:labelReset="props.datePicker?.resetButtonCTAText ?? $t('Reset')"
						:labelDone="props.datePicker?.doneCTAText ?? $t('Done')"
						:labelOneWay="props.datePicker?.oneWayCTAText ?? $t('One-way')"
						:isHideTodayIndicator="props.hideTodayDateIndicator"
						:disableCalendarPickerAnimation="props.disableCalendarPickerAnimation"
						@update:is-one-way="handleWatchIsOneWay"
					>
						<template #mobile-title>
							{{ props.datePickerQuestionMobile }}
						</template>
						
						<template v-if="currency" #before-cta-done>
							<span v-if="ondValueFrom && !sumPrice" v-html-sanitize="$t('Fare based on <strong>{currency}</strong>', { currency })"></span>

							<span v-if="ondValueFrom && ondValueTo && sumPrice" v-html-sanitize="$t('Trip from <strong>{sumPriceWithCurrency}</strong> per adult', { sumPriceWithCurrency })"></span>
						</template>
					</DateRangePicker>
				</div>
				<div
					:class="{
						'w-5/10': props.payByEnrichPoints || props.hidePromoCodeField,
						'w-3/10': !props.payByEnrichPoints && !props.hidePromoCodeField,
						'xl:w-full component-wrapper': true,
					}"
				>
					<PassengerAndCabinClass
						ref="passengerAndCabinClassEl"
						:labelText="props.passengerSelection?.passengerAndCabinClassLabel"
						:passengerLabel="props.passengerSelection?.passengerLabel"
						:adultLabel="props.passengerSelection?.adultLabel"
						:teenagerLabel="props.passengerSelection?.teenagerLabel"
						:childrenLabel="props.passengerSelection?.childrenLabel"
						:infantLabel="props.passengerSelection?.infantLabel"
						:cabinClassLabel="props.passengerSelection?.cabinClassLabel"
						:economyLabel="props.passengerSelection?.economyLabel"
						:businessLabel="props.passengerSelection?.businessLabel"
						:businessSuiteLabel="props.passengerSelection?.businessSuiteLabel"
						:isShowEconomyClass="!props.passengerSelection?.isHideEconomy"
						:isShowBusinessClass="!props.passengerSelection?.isHideBusiness"
						:isShowBusinessSuiteClass="!props.passengerSelection?.isHideBusinessSuite"
						:isCountryVariant="useIsCountryVariant(props.countryVariants, { origin: ondValueFrom, destination: ondValueTo })"
					>
						<template #after-passengers>
							<AppHyperlink
								class="font-semibold"
								:href="props.passengerSelection?.groupBookingPageURL"
								:showExternalLinkIcon="true"
								target="_blank"
							>
								{{ props.passengerSelection?.moreThan9PassengerDescription || $t('More than 9 passengers?') }}
							</AppHyperlink>
						</template>

						<template #mobile-title>
							{{ props.passengerSelectionQuestion }}
						</template>
					</PassengerAndCabinClass>
				</div>
				<div v-if="!props.payByEnrichPoints && !props.hidePromoCodeField" class="w-2/10 xl:w-full component-wrapper">
					<TextField
						ref="promoCodeTextField"
						v-model="promoCodeValue"
						name="promoCode"
						variant="booking-widget"
						:ariaLabel="props.promoCodeLabel"
						placeholder="E.g. 8990"
						:allowedKeys="/[a-zA-Z0-9]/"
					>
					</TextField>
				</div>
			</div>
		</div>
		<TransitionHeight
			:height="((isExpanded || isEditorMode || isMobileViewport) ? 'auto' : 0)"
			:transitionDuration="0.175"
			:class="((isExpanded || isEditorMode || isMobileViewport) ? 'overflow-visible' : '')"
			@transition-end="handleExpandTransitionEnd"
		>
			<div class="flex gap-6 xl:gap-3 lg:flex-col field-wrapper">
				<div class="flex flex-grow gap-6 xl:gap-3 lg:flex-col field-wrapper">
					<div v-if="props.expressBooking && props.expressBooking.turnOnExpressBooking && !props.payByEnrichPoints" class="w-3/10 lg:w-full">
						<p class="typo-category-title mb-4 mt-2 uppercase">{{ props.expressBooking?.searchOptionsLabel }}</p>
						<Checkbox
							v-model="isExpressBooking"
							:rootAttrs="{
								class: 'checkbox-express-booking -mt-2 mb-4',
							}"
							:ariaLabel="props.expressBooking?.expressBookingCheckboxLabel"
							name="isExpressBooking"
							:disabled="isDisabledExpressBooking"
							@update:model-value="isExpressBooking = $event"
							@change="handleExpressBookingChecked"
						>
							<p>{{ props.expressBooking?.expressBookingCheckboxLabel }}</p>
							<Tooltip class="inline-flex mb-5">
								<template #default>
									<div class="flex items-center">
										<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
									</div>
								</template>
								<template #tooltip-content>
									<div v-html-sanitize="props.expressBooking?.expressBookingTooltipDescription"></div>
								</template>
							</Tooltip>
						</Checkbox>
					</div>
				</div>
				<div class="flex justify-end lg:justify-start cta-wrapper mt-3">
					<CTAButton
						v-aa="[
							{
								clickName: !isExpressBooking ? props.searchFlightCTAText : props.expressBooking?.expressBookingSearchCta,
								clickComponentType: 'Button',
								componentName: 'SubTabBookFlight',
								componentID: props.componentIdAA,
							},
							{
								name: !isExpressBooking ? props.searchFlightCTAText : props.expressBooking?.expressBookingSearchCta,
								type: 'exit',
							},
						]"
						:sizeType="isMobileViewport ? 'small' : 'large'"
						:isSubmit="true"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						:disabled="isBtnSubmitDisabled"
					>
						{{ !isExpressBooking ? props.searchFlightCTAText : props.expressBooking?.expressBookingSearchCta }}
					</CTAButton>
				</div>
			</div>

			<div v-if="eligibleForBonusSideTrip && props.bstNoteTop && !isExpressBooking" class="flex mt-6">
				<div class="rounded-xl bg-secondary-teal-extralight flex-grow px-5 py-4">
					<ToggleSwitch
						v-model="optInBonusSideTrip"
						:ariaLabel="props.bstNoteTop"
						name="optInBonusSideTrip"
					>
						<div v-html-sanitize="props.bstNoteTop"></div>
					</ToggleSwitch>
					<div class="text-right">
						<AppHyperlink
							v-if="props.bstNoteCTAText"
							v-aa="[
								{
									clickName: props.bstNoteCTAText,
									clickComponentType: 'URL',
									componentName: 'SubTabBookFlight',
									componentID: props.componentIdAA,
									BST: true,
								},
								{
									name: props.bstNoteCTAText,
									type: isUrlMatchCurrentHostname(props.bstNoteCTAURL) ? 'other' : 'exit',
								},
							]"
							:href="props.bstNoteCTAURL"
							target="_blank"
							:showExternalLinkIcon="false"
						>
							{{ props.bstNoteCTAText }}
						</AppHyperlink>
					</div>
				</div>
			</div>

			<div
				v-if="props.note"
				class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
			>
				<icon-fas-circle-info class="fill-primary-blue-base text-base" />
				<div
					v-html-sanitize="props.note"
					class="text-sm"
				>
				</div>
			</div>

			<div
				v-if="showAlwaysOnEnrichNonLoginMsg"
				class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
			>
				<icon-fas-circle-info class="fill-primary-blue-base text-base" />
				<div
					v-html-sanitize="props.nonLoggedInMessageNote"
					class="text-sm"
				>
				</div>
			</div>

			<div
				v-if="enrichAlwaysOnEligibility && props.loggedInMessageNote"
				class="flex mt-6"
			>
				<div class="rounded-xl bg-secondary-teal-extralight flex-grow px-5 py-4">
					<ToggleSwitch
						v-model="optEnrichAlwaysOnOffer"
						:ariaLabel="props.loggedInMessageNote"
						name="optEnrichAlwaysOnOffer"
					>
						<div v-html-sanitize="props.loggedInMessageNote"></div>
					</ToggleSwitch>
				</div>
			</div>
		</TransitionHeight>
	</FormWrapper>

	<Teleport v-if="isLoading" to="body">
		<div class="generic-backdrop !bg-white/80">
			<div class="flex items-center justify-center h-full w-full">
				<AppSpinner />
			</div>
		</div>
	</Teleport>

	<component
		:is="isEditorMode ? 'div' : Teleport"
		to="body"
	>
		<Transition
			name="overlay-fade-and-zoom"
			appear
		>
			<div
				v-if="isExpressBookingPopUpVisible"
				ref="ExpressBookingPopUpEl"
				class="ExpressBookingPopUp backdrop"
				:class="{
					'!relative': isEditorMode,
				}"
				tabindex="-1"
				@keydown.esc.capture="dismissOverlayByUser"
				@click.self="dismissOverlayByUser"
			>
				<div class="overlay-container">
					<div class="overlay-header px-6 mt-4">
						<button
							type="button"
							class="absolute top-2 right-4"
							aria-label="Popup close button"
							@click="dismissOverlayByUser"
						>
							<icon-far-xmark class="w-6 h-6" />
						</button>
						<h3>{{ props.expressBooking?.expressBookingPopUpTitle }}</h3>
					</div>
					<div class="overlay-body px-6 mt-4">
						<div v-html-sanitize="props.expressBooking?.expressBookingPopUpDescription" class="author-html-container line-clamp-5"></div>
					</div>
					<div class="overlay-footer px-6 mt-6 mb-10">
						<CTAButton
							:ctaText="props.expressBooking?.expressBookingPopUpSignUpCtaLabel"
							sizeType="small"
							ctaSizing="fluid"
							styleType="secondary"
							containerClass="flex-grow basis-0 w-full"
							@click="handleRedirectToLoginAndSignUp({ status: 'signup' })"
						/>
						<AppHyperlink
							href="#"
							class="flex-grow basis-0 hover:no-underline w-full"
						>
							<CTAButton
								:ctaText="props.expressBooking?.expressBookingPopUpLoginCtaLabel"
								sizeType="small"
								ctaSizing="fluid"
								styleType="primary"
								containerClass="w-full"
								tabindex="-1"
								@click="handleRedirectToLoginAndSignUp({ status: 'login' })"
							/>
						</AppHyperlink>
						
					</div>
				</div>
			</div>
		</Transition>
	</component>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabBookFlight {
	[data-use-theme="enrich-portal"] {
		.toggle-group {
			@apply ml-0 pt-15;
		}

		.field-wrapper {
			flex-direction: column;
		}

		.component-wrapper {
			width: 100% !important;
		}

		.cta-wrapper{
			@apply m-auto;
		}
	}

	:deep(.checkbox-express-booking .label) {
		margin-top: -2px;
		
		* {
			@apply inline;
			line-height: 1rem;
		}
	}
}

.ExpressBookingPopUp {
	.overlay-container {
		max-width: 400px;
	}

	&.backdrop {
		position: fixed;
		z-index: 10000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: color.change(black, $alpha: 0.8);
		display: flex;
		justify-content: flex-end;
		transition: opacity 0.45s ease;
	}

	.overlay-container {
		background-color: var(--overlay-container-bg-color, white);
		max-width: 408px;
		width: 100%;
		margin: auto;
		overflow: hidden;
		@apply rounded-3xl;
	}

	.overlay-header {
		position: relative;
	}

	.overlay-header-image {
		aspect-ratio: 3/2;
	}

	.overlay-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		@apply gap-5;

		@media #{var.$query-max-md} {
			flex-direction: column;
			@apply gap-4;
		}
	}

	// animations
	.overlay-fade-and-zoom-enter-from,
	.overlay-fade-and-zoom-leave-to {
		opacity: 0;
	}

	.overlay-fade-and-zoom-enter-active,
	.overlay-fade-and-zoom-leave-active {
		transition: opacity 200ms;
	}

	.overlay-fade-and-zoom-enter-from,
	.overlay-fade-and-zoom-leave-to {
		.overlay-container {
			transform: scale(1.2);
		}
	}
	
	.overlay-fade-and-zoom-enter-active,
	.overlay-fade-and-zoom-leave-active {
		.overlay-container {
			transition: transform 200ms;
		}
	}
}


</style>
