<script>
export default {
	name: 'AirportPicker',
	inheritAttrs: false,
};
</script>

<script setup>
import yup from 'mh-yup';
import Multiselect from '@vueform/multiselect';
import OverlayBtmSticky from '~/components/OverlayBtmSticky.vue';
import { wrapQueryWithHTML } from '~/logic/helpers/string.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import useInternalValue from '~/logic/composables/useInternalValue.js';
import TextField from '~/components/form/TextField.vue';
import { MHScrollToElement } from '~/logic/helpers/dom.js';
import { i18nGlobal } from '~/logic/i18n.js';
import fuzzysort from 'fuzzysort';
import { groupBy as _groupBy } from 'lodash';


const props = defineProps({
	name: { type: String, required: true },
	
	options: { type: [Array, Function], default: () => [] },
	defaultValue: { type: String, default: null },
	modelValue: { type: [String, Object], default: null },
	variant: { type: String, default: '' },
	placeholderText: { type: String, default: '‎ ' },
	labelText: { type: String, default: '' },
	noResultsText: { type: String, default: i18nGlobal.t('No results found') },
	
	showOptionLocationIcon: { type: Boolean, default: false },
	showDropdownOnFocus: { type: Boolean, default: false },
	
	rootAttrs: { type: Object, default: () => ({}) },
	attrs: { type: Object, default: () => ({}) },
	
	required: { type: Boolean, default: false },
	requiredErrorMsg: { type: String, default: i18nGlobal.t('This field is required') },
	isGroups: { type: Boolean, default: null },
});
const emit = defineEmits([
	'open',
	'update:modelValue',
]);

const siteName = window.siteName;

const showPickerOverlay = ref(false);

// Keydown-event-Handler: To allow Tab-key behaving like Enter-key when selecting listing-option
const tabKeyToSelectHandler = (e) => {
	if (e.key === 'Tab') {
		e.preventDefault();
		e.stopPropagation();

		const selectedOption = rootEl.value.querySelector('.multiselect-option[data-pointed="true"]');
		selectedOption.click();
	}
};

const handleOnOpen = (instance) => {
	rootEl.value.addEventListener('keydown', tabKeyToSelectHandler);

	if (isMobileViewport.value) {
		showPickerOverlay.value = true;
	}
	if (internalValue.value) instance.setPointer(internalValue.value);
	emit('open', instance);
};

const handleUponSelection = () => {
	rootEl.value.removeEventListener('keydown', tabKeyToSelectHandler);
};

const handleMobileChange = (newValue) => {
	showPickerOverlay.value = false;
};


const isGroups = computed(() => {
	if (props.isGroups !== null) return props.isGroups;
	if (typeof props.options === 'function') {
		console.warn('Unable to auto determine if props.options is group or not, due to props.options being an async function. In this case, also provide props.isGroups. Defaults to false for now.');
		return false;
	}
	
	const firstData = props.options?.[0];
	if (!firstData) return false;
	return !!(firstData.label && firstData.options);
});

const multiselectMain = ref(null);

const handleOnInputFocus = () => {
	if (props.showDropdownOnFocus) {
		multiselectMain.value.open();
	}
};

const internalValue = useInternalValue('modelValue', {
	valueWatchCallback: (newValue) => {
		if (multiselectMain.value?.internalValue === newValue) return;
		if (newValue) {
			multiselectMain.value?.select(newValue);
		} else {
			multiselectMain.value?.clear();
		}
	},
});

const rootEl = ref(null);
const focusDummy = ref(null);
const hiddenTextField = ref(null);

const htmlValue = computed(() => {
	if (!internalValue.value) return '';
	if (typeof internalValue.value === 'string') return internalValue.value;
	// an Object
	return internalValue.value?.value ?? internalValue.value;
});


// const validation = computed(() => {
// 	if (!props.required) return null;
// 	return yup.string().required(props.requiredErrorMsg).nullable();
// });
const validationFunc = (value) => {
	if (!props.required) return true;
	
	if (typeof props.modelValue === 'object' && props.modelValue !== null) {
		// object
		return true;
	} else {
		// string
		const yupSchema = yup.string().required(props.requiredErrorMsg).nullable();
		
		try {
			yupSchema.validateSync(value);
			return true;
		} catch (err) {
			return err.errors[0];
		}
	}
};


const hiddenTextFieldMounted = () => {
	hiddenTextField.value.inputEl._mh_invalidFocus = () => {
		MHScrollToElement(rootEl.value, { additionalTopOffset: 60 });
		multiselectMain.value.input?.focus({ preventScroll: true });
	};
};


const focus = () => {
	multiselectMain.value?.input?.focus();
	multiselectMain.value?.open();
};

const handleClearClick = async (clearFunc, event) => {
	clearFunc();
	if (!isMobileViewport) {
		setTimeout(() => {
			multiselectMain.value.focus();
		}, 100);
	}
};
const handleOnInputBlur = () => {
	hiddenTextField.value?.setTouched(true);
};

const handleOverlayHidden = () => {
	focusDummy.value.focus();
};

const searchKeyword = ref(null);

const handleSearchChange = async (keyword) => {
	searchKeyword.value = keyword;
	
	if (rootEl.value) rootEl.value.querySelector('.multiselect-options').scrollTop = 0;
};

const filteredOptions = computed(() => {
	let result = props.options;
	if (!searchKeyword.value) return result;
	
	if (isGroups.value) {
		const flattened = result.reduce((acc, item) => {
			acc.push(...item.options.map((subItem) => ({ ...subItem, group: item.label })));
			return acc;
		}, []);
		
		// https://github.com/farzher/fuzzysort
		const fuzzyFiltered = fuzzysort.go(searchKeyword.value, flattened, {
			keys: ['code', 'searchString'],
		});
		
		// console.log('fuzzyFiltered = ', fuzzyFiltered);
		
		const filtered = fuzzyFiltered.filter((fuzzyResultItem) => {
			return fuzzyResultItem.score >= -25000; // -25k is an abritary value that I feel is good
		}).map((fuzzyResultItem) => {
			return fuzzyResultItem.obj;
		});
		
		const reconstructed = Object.entries(_groupBy(filtered, 'group')).map(([key, value]) => {
			return {
				label: key,
				options: value,
			};
		});
		
		
		nextTick(() => {
			result = reconstructed;
			// console.log('result after fuzzy =>', result);
		});
	} else {
		// https://github.com/farzher/fuzzysort
		const fuzzyFiltered = fuzzysort.go(searchKeyword.value, result, {
			keys: ['code', 'searchString'],
		});
		
		// console.log('fuzzyFiltered = ', fuzzyFiltered);
		
		result = fuzzyFiltered.filter((fuzzyResultItem) => {
			return fuzzyResultItem.score >= -25000; // -25k is an abritary value that I feel is good
		}).map((fuzzyResultItem) => {
			return fuzzyResultItem.obj;
		});
		
	}
	
	return result;
});

/* Disabled this line of code first as it causing bug on Firefly site when it scrolls to top when filteredOptions updated because the list updating a bit later */
// watch(filteredOptions, (newValue, oldValue) => {
// 	// force the UI to scroll to the top
	
// 	if (rootEl.value) rootEl.value.querySelector('.multiselect-options').scrollTop = 0;
// });

defineExpose({
	hiddenTextField,
	inputEl: hiddenTextField, // alias of 'hiddenTextField'
	focus,
});

</script>


<template>
<div
	ref="rootEl"
	class="AirportPicker"
	:class="{
		'has-validation-error': hiddenTextField?.hasValidationError && hiddenTextField?.meta.touched,
	}"
	:data-use-theme="siteName"
	v-bind="props.rootAttrs"
>
	<Multiselect
		ref="multiselectMain"
		v-model="internalValue"
		:options="filteredOptions"
		placeholder="‎ "
		label="name"
		trackBy="searchString"
		:searchable="true"
		:groups="isGroups"
		:canClear="false"
		:classes="{
			'dropdown': 'multiselect-dropdown md:hidden shadow-type-a mt-4 py-2 pr-2',
			'options': 'multiselect-options flex flex-col py-0 px-2 m-0 list-none styled-scrollbar',
		}"
		:attrs="{
			'onFocus': handleOnInputFocus,
			'onBlur': handleOnInputBlur,
			'aria-label': props.labelText,
			...(props.attrs ?? null),
		}"
		:object="true"
		:data-variant="props.variant"
		:noResultsText="props.noResultsText"
		:noOptionsText="props.noResultsText"
		v-bind="$attrs"
		class="multiselect-main"
		:class="[
			$attrs.class,
		]"
		@open="handleOnOpen"
		@select="handleUponSelection"
		@search-change="handleSearchChange"
	>
	
		<template #placeholder>
			<div class="placeholder-wrapper relative flex-grow mr-2 leading-none">
				<span class="text-label absolute font-semibold">{{ props.labelText }}<span v-if="props.required && (siteName === 'MHH' || siteName === 'firefly')" class="required-asterisk">*</span></span>
				<span class="text-placeholder text-neutral-grey-ultradark relative">{{ props.placeholderText }}</span>
			</div>
		</template>
		
		<template #singlelabel="{ value }">
			<div class="value-wrapper multiselect-single-label flex-grow mr-2 2xl:mr-0 rtl:mr-0 rtl:ml-2">
				<span class="text-label absolute font-semibold">{{ props.labelText }}<span v-if="props.required && (siteName === 'MHH' || siteName === 'firefly')" class="required-asterisk">*</span></span>
				<div class="text-value relative flex items-center">
					<div class="sr-only">{{ $t('Value is {value}', { value: value.displayValue ?? value.label }) }}</div>
					<span class="font-semibold value-label pr-2 rtl:(pr-0 pl-2) xs:text-sm truncate" aria-hidden="true">{{ value.displayValue ?? value.label }}</span>
					<div v-if="value.code" class="airport-code-tag ml-2 rtl:(ml-0 mr-2) self-start">{{ value.code }}</div>
				</div>
			</div>
		</template>
	
		<template #option="{ option, search }">
			<div class="flex flex-grow items-center">
				<div
					v-if="props.showOptionLocationIcon"
					class="mr-4 rtl:(mr-0 ml-4)"
				>
					<icon-far-location-dot class="m-auto fill-$text-color" />
				</div>
				<div class="flex flex-col leading-none pr-2 pl-2 rtl:pr-0">
					<span
						v-html-sanitize="wrapQueryWithHTML(option.label, { query: search, className: 'search-keyword-highlight' })"
						class="font-semibold"
					></span>
					<span
						v-if="option.description"
						class="text-neutral-grey-ultradark text-sm mt-1 leading-none"
					>
						{{ option.description }}
					</span>
					<!-- <span class="hidden-debug-info" role="presentation">
						🐞 {{ {
							isMultiCity: option.isMultiCity,
							isNewsletter: option.isNewsletter,
							isRedemption: option.isRedemption,
							isRevenue: option.isRevenue,
							isPopularDestination: option.isPopularDestination,
							isMhHoliday: option.isMhHoliday,
							displacement: option.displacement,
						} }}
					</span> -->
				</div>
				<div
					v-if="option.code"
					class="airport-code-tag ml-auto rtl:ml-0 rtl:mr-auto"
				>{{ option.code }}</div>
			</div>
		</template>
		
		<template #caret>
			<!--
				This .auxiliary-label-wrapper is sort of a "hack" to show a label while search is being inputted
				Because multiselect library hides placeholder AND value when search input has any value, our label would disappear during when user is typing.
				By creating an extra <div> in #caret, AND using the CSS trick to show and hide this <div>, we solved the problem.
			-->
			<div class="auxiliary-label-wrapper multiselect-single-label -order-1 flex-grow mr-2 leading-none">
				<span class="text-label absolute font-semibold">{{ props.labelText }}</span>
			</div>
			
			<!-- 👇 This is the actual caret -->
			<icon-far-chevron-down class="dropdown-caret mr-7 rtl:mr-0 rtl:ml-7 z-50 relative transition-transform duration-200" />
		</template>
		
		<template #clear="{ clear }">
			<button
				type="button"
				class="btn-clear rounded-full py-2 z-50 relative mr-2 rtl:mr-0 rtl:ml-2"
				:aria-label="$t('Clear field')"
				@mousedown.stop="handleClearClick(clear, $event)"
				@keydown.space.stop="handleClearClick(clear, $event)"
				@keydown.enter.stop="handleClearClick(clear, $event)"
			>
				<icon-fas-circle-xmark class="fill-neutral-grey-extradark" />
			</button>
		</template>
		
		<template v-for="(_, slotName) in $slots" #[slotName]="slotData">
			<slot :name="slotName" v-bind="slotData"></slot>
		</template>
	</Multiselect>
	
	<TextField
		ref="hiddenTextField"
		class=""
		:modelValue="htmlValue"
		:name="props.name"
		ariaLabel=""
		:validation="validationFunc"
		variant="input-hidden"
		@vue:mounted="hiddenTextFieldMounted"
	>
	</TextField>
	
	<div ref="focusDummy" class="focusable-dummy" tabindex="-1" aria-hidden="true"><!-- --></div>
</div>

<OverlayBtmSticky
	class="AirportPicker-overlay"
	:isVisible="showPickerOverlay && isMobileViewport"
	style="--overlayTitleHeight: 36px;"
	@update:is-visible="showPickerOverlay = $event; handleOverlayHidden();"
>
	<div
		class="pl-5 sticky top-0 bg-white z-1000 pb-3 w-full h-$overlayTitleHeight rtl:pl-0 rtl:pr-5"
	>
		<h6 class="font-bold line-clamp-1">
			<slot name="mobile-title">
				{{ props.labelText }}
			</slot>
		</h6>
	</div>
	
	<div ref="rootEl" class="AirportPicker">
		<Multiselect
			v-model="internalValue"
			:options="filteredOptions"
			:placeholder="$t('Type the airport name or code')"
			label="name"
			trackBy="searchString"
			:searchable="true"
			:groups="isGroups"
			:canClear="false"
			:classes="{
				'dropdown': 'multiselect-dropdown md:hidden mt-0 !flex',
				'options': 'multiselect-options flex flex-col py-0 m-0 list-none styled-scrollbar empty:hidden',
			}"
			:object="true"
			:data-variant="`in-mobile-overlay ${props.variant}`"
			:noResultsText="props.noResultsText"
			:noOptionsText="props.noResultsText"
			v-bind="$attrs"
			class="multiselect-main"
			:class="[
				$attrs.class,
			]"
			@select="handleMobileChange"
			@open="$emit('open', $event)"
			@search-change="handleSearchChange"
		>
			<template #placeholder>
				<div class="placeholder-wrapper relative flex-grow mr-2 leading-none">
					<span class="text-label absolute font-semibold">{{ props.labelText }}</span>
					<span class="text-placeholder text-neutral-grey-ultradark relative">Type the airport name or code</span>
				</div>
			</template>
			
			<template #option="{ option, search }">
				<div class="flex flex-grow items-center">
					<div
						v-if="props.showOptionLocationIcon"
						class="mr-2 rtl:mr-0 rtl:ml-2"
					>
						<icon-far-location-dot class="m-auto fill-$text-color" />
					</div>
					<div class="flex flex-col leading-none pr-2 pl-2 rtl:pr-0">
						<span
							v-html-sanitize="wrapQueryWithHTML(option.label, { query: search, className: 'search-keyword-highlight' })"
							class="font-semibold"
						></span>
						<span
							v-if="option.description"
							class="text-neutral-grey-ultradark text-sm mt-1 leading-none"
						>
							{{ option.description }}
						</span>
					</div>
					<div
						v-if="option.code"
						class="airport-code-tag ml-auto rtl:ml-0 rtl:mr-auto"
					>{{ option.code }}</div>
				</div>
			</template>
			
			<template #singlelabel="{ value }">
				<div class="value-wrapper multiselect-single-label flex-grow mr-2 leading-none">
					<span class="text-label absolute font-semibold">{{ props.labelText }}</span>
					<div class="text-value relative flex items-center gap-2">
						<div class="sr-only">Value is </div>
						<span class="font-semibold value-label pr-2 rtl:pr-0 rtl:pl-2 truncate">{{ value.displayValue ?? value.label }}</span>
						<div v-if="value.code" class="airport-code-tag">{{ value.code }}</div>
					</div>
				</div>
			</template>
			
			<template #clear="{ clear }">
				<button
					type="button"
					class="btn-clear rounded-full py-2 z-50 relative mr-2 rtl:mr-0 rtl:ml-2"
					:aria-label="$t('Clear field')"
					@mousedown.stop="handleClearClick(clear, $event)"
					@keydown.space.stop="handleClearClick(clear, $event)"
					@keydown.enter.stop="handleClearClick(clear, $event)"
				>
					<icon-fas-circle-xmark class="fill-neutral-grey-extradark" />
				</button>
			</template>
			
			<template v-for="(_, slotName) in $slots" #[slotName]="slotData">
				<slot :name="slotName" v-bind="slotData"></slot>
			</template>
		</Multiselect>
	</div>
</OverlayBtmSticky>

</template>


<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.AirportPicker-overlay {
	.overlay-container {
		height: 80vh;
	}
	.overlay-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-grow: 1;
		@apply ml-2;
	}
	.AirportPicker {
		--AirportPickerHeight: calc(100% - var(--overlayTitleHeight, 36px));
		height: var(--AirportPickerHeight);
		width: 100%;
	}
}

.AirportPicker-overlay[data-use-theme="MHH"] {
	.AirportPicker .multiselect-main[data-variant*="in-mobile-overlay"] {
		.multiselect-search:focus {
			--borderColor: var(--primary-mhh-teal-base);
		}

		.multiselect-search-wrapper::before {
			background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%23007E78"><path d="M504.1 471l-134-134C399.1 301.5 415.1 256.8 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c48.79 0 93.55-16.91 129-45.04l134 134C475.7 509.7 481.9 512 488 512s12.28-2.344 16.97-7.031C514.3 495.6 514.3 480.4 504.1 471zM48 208c0-88.22 71.78-160 160-160s160 71.78 160 160s-71.78 160-160 160S48 296.2 48 208z"></path></svg>') center center/100% auto;
			color: var(--primary-mhh-teal-base);
		}

		.airport-code-tag {
			background-color: var(--primary-mhh-teal-base);
		}

		.required-asterisk {
			@apply text-primary-mhh-teal-base;
		}

		.value-label {
			@apply text-primary-mhh-teal-base;
		}
	}
}

.AirportPicker-overlay[data-use-theme="firefly"] {
	.AirportPicker .multiselect-main[data-variant*="in-mobile-overlay"] {

		--ms-option-color-pointed: var(--primary-firefly-orange-base);
		--ms-option-bg-pointed: var(--neutral-firefly-white-light);

		--ms-option-color-selected: var(--primary-firefly-black-base);
		--ms-option-bg-selected: var(--secondary-firefly-orange-ultralight);

		--ms-option-color-selected-pointed:  var(--primary-firefly-orange-base);
		--ms-option-bg-selected-pointed: var(--neutral-firefly-white-light);

		&:focus,
		&:focus-within {
			--ms-border-color: var(--primary-firefly-orange-base);
		}
		

		.multiselect-search:focus {
			--borderColor: var(--primary-firefly-orange-base);
		}

		.multiselect-search-wrapper::before {
			background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%23FF7900"><path d="M504.1 471l-134-134C399.1 301.5 415.1 256.8 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c48.79 0 93.55-16.91 129-45.04l134 134C475.7 509.7 481.9 512 488 512s12.28-2.344 16.97-7.031C514.3 495.6 514.3 480.4 504.1 471zM48 208c0-88.22 71.78-160 160-160s160 71.78 160 160s-71.78 160-160 160S48 296.2 48 208z"></path></svg>') center center/100% auto;
			color: var(--primary-firefly-orange-base);
		}

		.airport-code-tag {
			background-color: var(--primary-firefly-orange-base);
			color: var(--secondary-firefly-black-base);
		}

		.required-asterisk {
			@apply text-secondary-firefly-orange-extradark;
		}

		.value-label {
			@apply text-secondary-firefly-orange-extradark;
		}
	}
}
</style>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';


.AirportPicker {
	--multiselectHeight: 54px;
	
	:deep(.multiselect-main) {
		--ms-max-height: 266px;
		--ms-bg: var(--neutral-grey-ultralight);
		--ms-line-height: 1.2;
		
		--ms-ring-width: 0;
		--ms-ring-color: var(--primary-blue-base);
		
		--ms-radius: 12px;
		--ms-dropdown-bg: #FFFFFF;
		--ms-border-width: 2px;
		--ms-border-color: var(--neutral-grey-light);
		--ms-dropdown-border-color: var(--neutral-grey-light);
		--ms-dropdown-border-width: 2px;
		--ms-dropdown-radius: 12px;
		
		--ms-option-bg-pointed: var(--neutral-grey-extralight);
		--ms-option-color-pointed: var(--primary-blue-base);
		
		--ms-option-color-selected: var(--text-color);
		--ms-option-color-selected-pointed: var(--primary-blue-base);
		--ms-option-bg-selected: var(--primary-blue-extralight);
		--ms-option-bg-selected-pointed: #{color.adjust(var.$primary-blue-extralight, $lightness: -7%)};
		
		transition: border-color 0.2s, background-color 0.2s;
		min-height: 0;
		height: var(--multiselectHeight);
		justify-content: flex-end;
		
		&:hover {
			--ms-border-color: var(--neutral-grey-base);
		}
		
		&:focus, &:focus-within {
			--ms-border-color: var(--primary-blue-base);
			
			.text-placeholder {
				opacity: 1;
			}
		}
		
		&.is-open {
			--ms-bg: white;
			border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) var(--ms-radius, 4px) var(--ms-radius, 4px);
			
			.text-label {
				font-size: 12px;
				transform: translate(0, -0.65rem);
			}
			.text-placeholder {
				opacity: 1;
			}
			> .multiselect-search-wrapper .dropdown-caret {
				transform: rotate(180deg);
			}
		}
		
		.text-label {
			transition: all 0.25s;
			transform-origin: 0 0;
			color: var(--neutral-grey-ultradark);
		}
		.placeholder-wrapper {
			pointer-events: none;
			@apply px-5 pt-3.5 pb-3;
		}
		.text-placeholder {
			opacity: 0;
			top: 6px;
		}
		.value-wrapper, .auxiliary-label-wrapper {
			@apply px-5 pt-4.5 pb-2;
			margin-top: 1px;
			
			.text-label {
				font-size: 12px;
				transform: translate(0, -0.65rem);
			}
		}
		.value-label {
			@apply text-primary-blue-base;
		}
		// hide the auxiliary-label-wrapper when either 'placeholder' or 'value' is present
		.placeholder-wrapper ~ .auxiliary-label-wrapper,
		.value-wrapper ~ .auxiliary-label-wrapper {
			display: none;
		}
	}
	:deep(.multiselect-caret) {
		width: 10px;
		height: 15px;
		transition-duration: 200ms;
		margin-left: auto;
		@apply mr-6;
	}
	:deep(.multiselect-search) {
		@apply px-5 pt-3.5 pb-1;
		
		&:focus {
			~ .placeholder-wrapper .text-label {
				font-size: 12px;
				transform: translate(0, -0.65rem);
			}
		}
		&:focus-visible {
			outline: 0;
		}
	}
	:deep(.multiselect-single-label) {
		@apply line-clamp-2;
		position: static;
	}
	:deep(.multiselect-dropdown) {
		overflow-y: hidden;
		border-radius: 12px;
		transform: translate(var(--dropdownTranslateX, 0), calc(100% + 0.37rem));
		z-index: 7900;
	}
	:deep(.multiselect-options) {
		overflow-y: auto;
		max-height: 266px;
		overscroll-behavior: none;
		scroll-behavior: smooth;
	}
	:deep(.multiselect-option) {
		border-radius: 8px;
		
		html[dir="rtl"] & {
			text-align: right;
		}
		
		mark {
			font-weight: 600;
			color: black;
			background-color: transparent;
		}
	}
	:deep(.multiselect-caret) {
		width: 10px;
		height: 15px;
		transition-duration: 200ms;
		margin-left: auto;
		@apply mr-6;
	}
	:deep(.multiselect-group-label) {
		background-color: transparent;
		color: var(--neutral-grey-extradark);
		text-transform: uppercase;
		font-size: 12px;
		@apply border-t-2 pt-5 mt-4;
	}
	:deep(.multiselect-group:first-child .multiselect-group-label) {
		border-width: 0;
		margin-top: 0;
		@apply pt-2;
	}
	:deep(.Dropdown .multiselect-dropdown) {
		overflow-y: visible;
	}
}

.airport-code-tag {
	color: white;
	background-color: var(--primary-blue-base);
	border-radius: 6px;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	width: 54px;
	flex: 0 0 54px;
	@apply py-0.75 text-xs;
	
	&:empty {
		opacity: 0;
		height: 22px;
	}
}

// this approach got height issue
/* .AirportPicker :deep(.multiselect-main[data-variant*="in-mobile-overlay"]) {
	width: calc(100% - 2.5rem);
	
	.multiselect-caret { display: none; }
	.multiselect-dropdown {
		border-radius: 0;
		border: 0;
		left: -1.25rem;
		right: -1.25rem;
	}
	.multiselect-options {
		max-height: none;
	}
	.multiselect-group-label {
		@apply pl-5 pr-3;
	}
	.multiselect-group-options {
		@apply pl-5 pr-3;
	}
} */

.AirportPicker :deep(.multiselect-main[data-variant*="booking-widget"]) {
	/* --ms-font-size: 0.875rem; */
	
	/* Note: this is MIN query, not MAX */
	@media #{var.$query-min-md} {
		height: 64px;
		
		.multiselect-search {
			@apply pt-4.5 pb-0;
		}
		.text-placeholder {
			top: 8px;
		}
		.value-wrapper,
		.auxiliary-label-wrapper {
			@apply pt-5.7;
		}
		.text-value {
			top: 4px;
		}
	}
}

.AirportPicker :deep(.multiselect-main[data-variant*="bst-widget"]) {
	height: 64px;

	.multiselect-search {
		@apply pt-4.5 pb-0;
	}
	.text-placeholder {
		top: 8px;
	}
	.value-wrapper,
	.auxiliary-label-wrapper {
		@apply pt-5.7;
	}
	.text-value {
		top: 4px;
	}
}

.AirportPicker :deep(.multiselect-main[data-variant*="in-mobile-overlay"]) {
	--ms-border-width: 0;
	--ms-dropdown-border-width: 0;
	--ms-ring-width: 0;
	--ms-bg: transparent;
	flex-direction: column;
	max-width: 100%;
	height: 100%;
	@apply pt-0;
	
	.multiselect-search-wrapper {
		height: 54px;
		position: relative;
		width: calc(100% - 2.5rem); // 2.5rem = px-5
		flex: 0 0 54px;
		align-self: stretch;
		@apply mb-4 mx-auto;
		
		&::before {
			content: '';
			background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%230D4689"><path d="M504.1 471l-134-134C399.1 301.5 415.1 256.8 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c48.79 0 93.55-16.91 129-45.04l134 134C475.7 509.7 481.9 512 488 512s12.28-2.344 16.97-7.031C514.3 495.6 514.3 480.4 504.1 471zM48 208c0-88.22 71.78-160 160-160s160 71.78 160 160s-71.78 160-160 160S48 296.2 48 208z"></path></svg>') center center/100% auto;
			display: block;
			width: 18px;
			height: 18px;
			color: var(--primary-blue-base);
			position: absolute;
			left: 16px;
			z-index: 100;
			
			html[dir="rtl"] & {
				left: 0;
				right: 16px;
			}
		}
	}
	.multiselect-search {
		--borderColor: var(--neutral-grey-light);
		--bgColor: var(--neutral-grey-ultralight);
		--labelColor: var(--neutral-grey-ultradark);
		--placeholderColor: var(--neutral-grey-ultradark);
		--inputValueColor: var(--text-color);
		--prefixColor: var(--primary-blue-base);
		outline: 0;
		height: 100%;
		border-radius: 12px;
		background-color: var(--bgColor);
		border: 2px solid var(--borderColor);
		transition-property: border-color;
		transition-duration: 0.2s;
		color: var(--inputValueColor);
		padding-left: 42px;
		@apply pt-0 pb-0;
		
		&:hover {
			--borderColor: var(--neutral-grey-base);
		}
		&:focus {
			--borderColor: var(--primary-blue-base);
		}
		
		html[dir="rtl"] & {
			padding-left: 0;
			padding-right: 42px;
		}
	}
	.text-placeholder {
		width: 100%;
		top: 0;
		opacity: 1;
	}
	.text-label {
		display: none;
	}
	.btn-clear {
		@apply ml-auto;
		
		html[dir="rtl"] & {
			@apply ml-2 mr-auto;
		}
	}
	.placeholder-wrapper {
		left: 24px;
		top: -1px;
		
		html[dir="rtl"] & {
			left: 0;
			right: 24px;
		}
	}
	.value-wrapper {
		height: 54px;
		left: 24px;
		position: relative;
		@apply pt-3.75;
		
		html[dir="rtl"] & {
			left: 0;
			right: 24px;
		}
	}

	.value-label {
		@apply text-primary-blue-base;
	}
	.multiselect-caret {
		display: none;
	}
	.multiselect-dropdown {
		width: 100%;
		flex-grow: 1;
		top: 0;
		transform: none;
		max-height: 100%;
		z-index: 0;
		border-radius: 0;
		position: relative;
		@apply mb-2;
	}
	.multiselect-options {
		height: 100%;
		max-height: 100%;
	}
	.multiselect-option {
		@apply mb-2;
		
		html[dir="rtl"] & {
			@apply pl-3 pr-3;
		}
	}
	.multiselect-group-label {
		@apply px-5;
	}
	.multiselect-group-options {
		@apply px-5;
	}
}

.AirportPicker :deep(.multiselect-main[data-variant*="in-mobile-overlay"][data-variant*="non-searchable"]) {
	--ms-border-width: 0;
	--ms-dropdown-border-width: 0;
	--ms-ring-width: 0;
	--ms-bg: transparent;
	max-width: 100%;
	height: 100%;
	align-items: flex-start;
	flex-direction: column;
	@apply pt-0;
	
	.multiselect-caret {
		display: none;
	}
	.multiselect-dropdown {
		width: 100%;
		flex-grow: 1;
		top: 0;
		transform: none;
		position: relative;
		max-height: 100%;
		z-index: 0;
		border-radius: 0;
		@apply mb-2;
	}
	.multiselect-options {
		height: 100%;
		max-height: 100%;
	}
	.multiselect-group-label {
		@apply px-5;
	}
	.multiselect-group-options {
		@apply px-5;
	}
}


.AirportPicker.has-validation-error :deep(.multiselect-main) {
	--ms-border-color: var(--semantic-red-light);
	
	.dropdown-caret {
		fill: var(--ms-border-color);
	}
	.text-label {
		color: var(--semantic-red-base);
	}
	&:hover {
		--ms-border-color: #{var.$semantic-red-base-50-opacity};
	}
	&:focus, &:focus-within {
		--ms-border-color: var(--semantic-red-base);
	}
}

[data-use-theme="MHH"] {
	.multiselect-main {
		--ms-ring-color: var(--primary-mhh-teal-base);
		--ms-option-color-pointed: var(--primary-mhh-teal-base);
		--ms-option-color-selected: var(--primary-mhh-teal-base);
		--ms-option-color-selected-pointed:  var(--primary-mhh-teal-base);

		&:focus,
		&:focus-within {
			--ms-border-color: var(--primary-mhh-teal-base);
		}
	}

	.airport-code-tag {
		background-color: var(--primary-mhh-teal-base);
	}

	.fill-primary-blue-base {
		fill: var(--primary-mhh-teal-base);
	}

	.value-label {
		@apply text-primary-mhh-teal-base;
	}

	:deep(.multiselect-option.is-pointed) {
		color: var(--primary-mhh-teal-base);
	}

	.required-asterisk {
		@apply text-primary-mhh-teal-base;
	}

}

.AirportPicker[data-use-theme="firefly"] {

	:deep(.multiselect-main) {

		--ms-option-color-pointed: var(--primary-firefly-orange-base);
		--ms-option-bg-pointed: var(--neutral-firefly-white-light);

		--ms-option-color-selected: var(--primary-firefly-black-base);
		--ms-option-bg-selected: var(--secondary-firefly-orange-ultralight);

		--ms-option-color-selected-pointed:  var(--primary-firefly-orange-base);
		--ms-option-bg-selected-pointed: var(--neutral-firefly-white-light);

		&:focus,
		&:focus-within {
			--ms-border-color: var(--primary-firefly-orange-base);
		}
	}

	:deep(.multiselect-search) {
		color: var(--secondary-firefly-orange-extradark);
	}

	.airport-code-tag {
		background-color: var(--primary-firefly-orange-base);
		color: var(--secondary-firefly-black-base);
	}

	.value-label {
		@apply text-secondary-firefly-orange-extradark;
	}

	.required-asterisk {
		@apply text-secondary-firefly-orange-extradark;
	}

}
</style>
