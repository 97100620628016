import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { waitForDocumentReadyState } from '~/logic/helpers/dom.js';
import { waitFor } from '~/logic/helpers/utils';
import { i18nGlobal } from '~/logic/i18n.js';
import { getDisplacementInKilometer } from '~/logic/helpers/gps.js';
import { forEachRight as _forEachRight, forEach as _forEach } from 'lodash';


const GROUP_ID = {
	NEAR_YOU: 'NEAR_YOU',
	POPULAR_DEST: 'POPULAR_DEST',
	WITH_MH: 'WITH_MH',
	CODESHARE: 'CODESHARE',
	EMPTY_LABEL: 'EMPTY_LABEL',
	WITH_AMAL: 'WITH_AMAL',
};
const GROUP_ID_TO_LABEL_MAP = {
	[GROUP_ID.NEAR_YOU]: i18nGlobal.t('Near You'),
	[GROUP_ID.POPULAR_DEST]: i18nGlobal.t('Popular Destinations'),
	[GROUP_ID.WITH_MH]: i18nGlobal.t('With Malaysia Airlines'),
	[GROUP_ID.CODESHARE]: i18nGlobal.t('With Codeshare and Partner Network'),
	[GROUP_ID.EMPTY_LABEL]: ' ',
	[GROUP_ID.WITH_AMAL]: i18nGlobal.t('With Amal'),
};

let hasInitiated = false;
let hasInitCompleted = false;
let hasInitiated_LocaleTranslation = false;

const baseList = ref(null);
const baseListError = ref(null);
const isBaseListLoading = ref(true);

const baseList_BST = ref(null);
const baseListError_BST = ref(null);
const isBaseListLoading_BST = ref(true);

const oWAirportList = ref(null);
const isOWAirportListLoading = ref(true);
const owListError = ref(null);

const currentSiteLocale = ref(null);
const siteName = window.siteName;


class ONDFetchService {
	constructor ({
		endpoint,
		localeParam,
	} = {}) {
		this.endpoint = endpoint;
		// this.endpoint = 'https://run.mocky.io/v3/0206dc99-ccf0-42b2-8648-5ca57cca22e6'; // <-- enable for empty content
		this.localeParam = localeParam;
		this.type = 'base';
	}
	
	async fetchBaseList () {
		const apiResponse = await axios.get(this.endpoint, { params: { type: this.type, locale: this.localeParam } });
		
		if (String(apiResponse.status).startsWith('4') || String(apiResponse.status).startsWith('5')) {
			throw new Error(apiResponse.statusText);
		}
		
		// console.log('apiResponse.data = ', apiResponse.data);
		return apiResponse.data;
	}
	async fetchFromList (toValue) {
		const apiResponse = await axios.get(this.endpoint, { params: { type: this.type, des: unref(toValue), locale: this.localeParam } });
		if (String(apiResponse.status).startsWith('4') || String(apiResponse.status).startsWith('5')) {
			throw new Error(apiResponse.statusText);
		}
		
		// console.log('apiResponse.data.originList = ', apiResponse.data.originList);
		return apiResponse.data.originList;
	}
	async fetchToList (fromValue) {
		const apiResponse = await axios.get(this.endpoint, { params: { type: this.type, ori: unref(fromValue), locale: this.localeParam } });
		if (String(apiResponse.status).startsWith('4') || String(apiResponse.status).startsWith('5')) {
			throw new Error(apiResponse.statusText);
		}
		
		// console.log('apiResponse.data.destinationList = ', apiResponse.data.destinationList);
		return apiResponse.data.destinationList;
	}

	// Fetches airport list from static data
	static async fetchOWAirportList () {
		const apiResponse = await axios.get(unref(useGlobalAEMState().pageProperties).oneWorldDropDownONDListUrl);
		return apiResponse.data;
	}
}

class ONDFetchService_BST extends ONDFetchService {
	constructor ({
		endpoint,
	} = {}) {
		super(...arguments);
		this.type = 'bst';
	}
}

let _fetchService = null;
let _fetchService_BST = null;

const init = async () => {
	let currentTranslationLocale = currentSiteLocale.value;
	const waitPageProperties = waitFor(() => !!unref(useGlobalAEMState().pageProperties), 500);
	await waitPageProperties.start();

	_fetchService = new ONDFetchService({
		endpoint: getDropdownONDListUrl(siteName),
		...(currentTranslationLocale ? { localeParam: currentTranslationLocale } : null),
	});
	
	isBaseListLoading.value = true;
	
	try {
		const data = await _fetchService.fetchBaseList();
		baseList.value = data;
	} catch (err) {
		baseListError.value = err.message || err;
	}
	isBaseListLoading.value = false;
	
	// ==========================================
	// ---------- BST ----------
	_fetchService_BST = new ONDFetchService_BST({
		endpoint: getDropdownONDListUrl(siteName),
		...(currentTranslationLocale ? { localeParam: currentTranslationLocale } : null),
	});
	isBaseListLoading_BST.value = true;

	oWAirportList.value = await ONDFetchService.fetchOWAirportList();
	isOWAirportListLoading.value = true;

	try {
		if (siteName !== 'firefly') {
			const data = await _fetchService_BST.fetchBaseList();
			baseList_BST.value = data;
		}
	} catch (err) {
		baseListError_BST.value = err.message || err;
	}
	isBaseListLoading_BST.value = false;
	
	hasInitCompleted = true;
};

const getFetchService = async ({ isBST = false } = {}) => {
	if (isBST) {
		if (_fetchService_BST) return _fetchService_BST;
		const wait = waitFor(() => !!_fetchService_BST, 750);
		await wait.start();
		return _fetchService_BST;
	} else {
		if (_fetchService) return _fetchService;
		const wait = waitFor(() => !!_fetchService, 750);
		await wait.start();
		return _fetchService;
	}
};

const appendAirportInfo = (item) => {
	const dataObj = {
		_original: item,
		value: item.airportCode,
		displayValue: item.locale?.cityName ?? item.city,
		label: `${item.locale?.cityName ?? item.city}, ${item.locale?.countryName ?? item.countryName}`,
		airportCode: item.airportCode,
		code: item.airportCode,
		city: item.locale?.cityName ?? item.city,
		searchString: `${item.airportCode} | ${item.city}, ${item.countryName} | ${item.airportName} | ${item.locale?.cityName ?? ''}, ${item.locale?.countryName ?? ''} | ${item.locale?.airportName ?? ''}`,
		description: item.locale?.airportName ?? item.airportName,
		countryName: item.countryName,
		countryCode: item.countryCode,
		// latitude: parseFloat(item.lattitude), // TODO BE to fix this 
		latitude: parseFloat(item.latitude),
		longitude: parseFloat(item.longitude),
		...(item.domesticDestinations ? { domesticDestinations: item.domesticDestinations } : null),
		isMultiCity: item.isMultiCity,
		isNewsletter: item.isNewsletter,
		isRedemption: item.isRedemption,
		isFlightStatus: item.isFlightStatus,
		isRevenue: item.isRevenue,
		isPopularDestination: item.isPopularDestination,
		isMhHoliday: item.isMhHoliday,
		...(('isAmalFlag' in item) ? { isAmalFlag: item.isAmalFlag } : null),
		...(('travelPeriodFrom' in item) ? { travelPeriodFrom: item.travelPeriodFrom } : null),
		...(('travelPeriodTo' in item) ? { travelPeriodTo: item.travelPeriodTo } : null),
		...(('isAircargo' in item) ? { isAircargo: item.isAircargo } : null),
		...(('isCheckin' in item) ? { isCheckin: item.isCheckin } : null),
		...(('supportedCurrencies' in item) ? { supportedCurrencies: item.supportedCurrencies } : null),
		...(('defaultCurrency' in item) ? { defaultCurrency: item.defaultCurrency } : null),
	};
	return dataObj;
};

export const getONDValueByAirportCode = async (airportCode, { direction = 'from', isBST = false } = {}) => {
	// console.warn(`getONDValueByAirportCode() is deprecated because calling it this way is usually wrong.
	// Please use updateFromValue() / updateToValue(). See source code for <SubTabBookFlight> for example: logic in registerQueryChange() callback function.`);
	
	const waitForInitComplete = waitFor(() => hasInitCompleted === true);
	await waitForInitComplete.start();
	
	if (isBST) {
		const waitLoadingFinish = waitFor(() => isBaseListLoading_BST.value === false);
		await waitLoadingFinish.start();
	} else {
		const waitLoadingFinish = waitFor(() => isBaseListLoading.value === false);
		await waitLoadingFinish.start();
	}
	
	let list = [];
	
	if (direction === 'from') {
		list = baseList.value?.originList;
		if (isBST) {
			list = baseList_BST.value?.originList;
		}
	} else {
		list = baseList.value?.destinationList;
		if (isBST) {
			list = baseList_BST.value?.destinationList;
		}
	}
	
	const foundItem = list.find((item) => {
		return item.airportCode === airportCode;
	});
	
	if (foundItem) return appendAirportInfo(foundItem);
	return null;
};

/**
 * Covert flat array data list into groups
 * Input: [{A}, {B}, {C}, {D}]
 * Output: [{ label: '', options: [{A}, {B}] }, { label: '', options: [{C}] }, { label: '', options: [{D}] }]
 * @param {Array} list
 * @param {Array (multi-dimensional)} groupingCriterias [ ['GROUP_ID', 'GROUP_PREDICATE'], ... ]
 * GROUP_PREDICATE = property(flag) | property(groupId) | function | boolean
	* if property, and its item[property] is Boolean and is true, add to group
	* if property, and its item[property] is String and matches group id, add to group
	* if function and upon calling function(item) it returns true, add to group
	* if boolean and is true, add to group
 * groupingCriterias item can be null, in that case, it does nothing and is not treated as a group.
 * @returns {Array}
 */
const processDataListIntoGroups = (list, _groupingCriterias = []) => {
	const groupingCriterias = _groupingCriterias.filter(Boolean);
	
	let result = groupingCriterias.map(([groupId, groupPredicate]) => {
		return {
			label: GROUP_ID_TO_LABEL_MAP[groupId],
			options: [],
			groupId,
		};
	});
	
	list.forEach((item) => {
		_forEach(groupingCriterias, ([groupId, groupPredicate], criteriaIndex) => {
			let includeThisItem = false;
			if (typeof groupPredicate === 'string') {
				if (item[groupPredicate] === true || item[groupPredicate] === groupId) {
					includeThisItem = true;
				}
			} else if (typeof groupPredicate === 'function') {
				if (groupPredicate(item) === true) includeThisItem = true;
			} else if (typeof groupPredicate === 'boolean') {
				if (groupPredicate === true) includeThisItem = true;
			}
			if (includeThisItem) {
				result[criteriaIndex].options.push(item);
				return false; // exit this forEach early, so that this same item won't be evaluated for other groups
			}
			return true; // let the loop continue
		});
	});

	// remove empty group
	result = result.filter((group) => (group.options.length > 0));
	return result;
};

const sortNearYou_MUTATE = (list) => {
	const groupData = list.find((listItem) => listItem.groupId === GROUP_ID.NEAR_YOU);
	if (!groupData) return;
	groupData.options.sort((a, b) => {
		return a?.displacement - b?.displacement;
	});
};

const processDatalist = (dataList, { listIdentifier, direction, excludeList, userCoords, currentPageCountry } = {}) => {
	let result = unref(dataList);
	
	if (!Array.isArray(result)) {
		console.warn(`processDatalist() 'dataList' is not an Array. No-op.`);
		return result;
	}
	if (result.length === 0) return result;
	result = result.map(appendAirportInfo);
	
	const userPosition = userCoords ? {
		latitude: userCoords.latitude,
		longitude: userCoords.longitude,
	} : null;
	
	switch (unref(listIdentifier)) {
		case 'FLIGHT_SEARCH_BST': {
			const listMapByAirportCode = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;

				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					// does nothing
				} else {
					// new entry
					let groupTo = '';
					let displacement = -1;

					// Group by 'Near You'
					if (direction === 'from') {
						if (userPosition) {
							// console.log('BST geolocation available, group by GPS');
							// geolocation available, group by GPS
							displacement = getDisplacementInKilometer(userPosition, {
								latitude: item.latitude,
								longitude: item.longitude,
							});
							
							// console.log('displacement = ', displacement);

							if (displacement >= 0 && displacement <= 75) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						} else {
							// console.log('BST no geolocation, group by country');
							// no geolocation, group by country
							if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						}
					}

					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
						displacement,
					});
				}
			});

			result = Array.from(listMapByAirportCode.values());
			
			result = processDataListIntoGroups(result, [
				direction === 'from' ? [GROUP_ID.NEAR_YOU, 'groupTo'] : null,
				[GROUP_ID.WITH_MH, true],
			]);
			
			sortNearYou_MUTATE(result);
			break;
		}
		case 'FLIGHT_SEARCH_CASH': {
			const listMapByAirportCode = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isRevenue = item.isRevenue;
				
				if (!isRevenue) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;
				
				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					const retrieved = listMapByAirportCode.get(airportCode);
					
					if (item.isAmalFlag) {
						retrieved.isAmalFlag = true;
						retrieved.groupTo = GROUP_ID.WITH_AMAL;
					}
					if (!item.isAmalFlag && item.isRedemption) {
						retrieved.isRedemption = true;
						// if (retrieved.code === 'LHR') debugger;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU) retrieved.groupTo = GROUP_ID.WITH_MH;
					}
					if (item.isPopularDestination) {
						retrieved.isPopularDestination = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU && direction === 'to') retrieved.groupTo = GROUP_ID.POPULAR_DEST;
					}
					if (item.isNewsletter) {
						retrieved.isNewsletter = true;
						retrieved._original = item._original;
					}
					if (item.isMhHoliday) {
						retrieved.isMhHoliday = true;
						retrieved._original = item._original;
					}

				} else {
					// new entry
					let groupTo = '';
					
					if (direction === 'to' && item.isPopularDestination) {
						groupTo = GROUP_ID.POPULAR_DEST;
					} else if (item.isAmalFlag) {
						groupTo = GROUP_ID.WITH_AMAL;
					} else {
						groupTo = item.isRedemption ? GROUP_ID.WITH_MH : GROUP_ID.CODESHARE;
					}
					
					let displacement = -1;
					
					// Group by 'Near You'
					if (direction === 'from') {
						if (userPosition) {
							// geolocation available, group by GPS
							displacement = getDisplacementInKilometer(userPosition, {
								latitude: item.latitude,
								longitude: item.longitude,
							});
							
							if (displacement >= 0 && displacement <= 75) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						} else {
							// no geolocation, group by country
							if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						}
					}
					
					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
						displacement,
					});
				}
			});
			
			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				direction === 'from' ? [GROUP_ID.NEAR_YOU, 'groupTo'] : null,
				// 👇 below is the alternate logic using function to determine, but is commented out for now, as it is more efficient to use the string 'groupTo'
				/* direction === 'from' ? [GROUP_ID.NEAR_YOU, (item) => {
					if (item.displacement === -1) {
						if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
							return true;
						}
					} else if (item.displacement >= 0 && item.displacement <= 75) {
						return true;
					}
					return false;
				}] : null, */
				direction === 'to' ? [GROUP_ID.POPULAR_DEST, 'groupTo'] : null,
				[GROUP_ID.WITH_AMAL, 'groupTo'],
				[GROUP_ID.WITH_MH, 'groupTo'],
				[GROUP_ID.CODESHARE, 'groupTo'],
				[GROUP_ID.EMPTY_LABEL, true],
			]);
			
			sortNearYou_MUTATE(result);
			break;
		}
		case 'FLIGHT_SEARCH_ENRICH': {
			const listMapByAirportCode = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isRedemption = item.isRedemption;

				if (!isRedemption) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					const retrieved = listMapByAirportCode.get(airportCode);

					if (item.isAmalFlag) {
						retrieved.isAmalFlag = GROUP_ID.WITH_AMAL;
					}
					if (!item.isAmalFlag && item.isRedemption) {
						retrieved.isRedemption = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU) retrieved.groupTo = GROUP_ID.WITH_MH;
					}
					if (item.isPopularDestination) {
						retrieved.isPopularDestination = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU && direction === 'to') retrieved.groupTo = GROUP_ID.POPULAR_DEST;
					}
					if (item.isNewsletter) {
						retrieved.isNewsletter = true;
						retrieved._original = item._original;
					}
					if (item.isMhHoliday) {
						retrieved.isMhHoliday = true;
						retrieved._original = item._original;
					}
				} else {
					// new entry
					let groupTo = '';

					if (direction === 'to' && item.isPopularDestination) {
						groupTo = GROUP_ID.POPULAR_DEST;
					} else if (item.isAmalFlag) {
						groupTo = GROUP_ID.WITH_AMAL;
					} else {
						groupTo = GROUP_ID.WITH_MH;
					}

					let displacement = -1;

					// Group by 'Near You'
					if (direction === 'from') {
						if (userPosition) {
							// geolocation available, group by GPS
							displacement = getDisplacementInKilometer(userPosition, {
								latitude: item.latitude,
								longitude: item.longitude,
							});

							if (displacement >= 0 && displacement <= 75) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						} else {
							// no geolocation, group by country
							if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						}
					}

					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
						displacement,
					});
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				direction === 'from' ? [GROUP_ID.NEAR_YOU, 'groupTo'] : null,
				direction === 'to' ? [GROUP_ID.POPULAR_DEST, 'groupTo'] : null,
				[GROUP_ID.WITH_MH, 'groupTo'],
				[GROUP_ID.EMPTY_LABEL, true],
			]);

			sortNearYou_MUTATE(result);
			break;
		}
		case 'FLIGHT_SEARCH_OW_REDEMPTION': {
			const listMapByAirportCode = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;
				// new entry
				let groupTo = '';

				if (direction === 'to' && item.isPopularDestination) {
					groupTo = GROUP_ID.POPULAR_DEST;
				} else {
					groupTo = GROUP_ID.WITH_MH;
				}

				let displacement = -1;

				// Group by 'Near You'
				if (direction === 'from') {
					if (userPosition) {
						// geolocation available, group by GPS
						displacement = getDisplacementInKilometer(userPosition, {
							latitude: item.latitude,
							longitude: item.longitude,
						});

						if (displacement >= 0 && displacement <= 75) {
							groupTo = GROUP_ID.NEAR_YOU;
						}
					} else {
						// no geolocation, group by country
						if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
							groupTo = GROUP_ID.NEAR_YOU;
						}
					}
				}

				listMapByAirportCode.set(airportCode, {
					...item,
					groupTo,
					displacement,
				});
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				direction === 'from' ? [GROUP_ID.NEAR_YOU, 'groupTo'] : null,
				direction === 'to' ? [GROUP_ID.POPULAR_DEST, 'groupTo'] : null,
				[GROUP_ID.WITH_MH, 'groupTo'],
				[GROUP_ID.EMPTY_LABEL, false],
			]);

			sortNearYou_MUTATE(result);
			break;
		}
		case 'FLIGHT_SEARCH_MULTI_CITY': {
			const listMapByAirportCode = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isMultiCity = item.isMultiCity;

				if (!isMultiCity) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					const retrieved = listMapByAirportCode.get(airportCode);
					
					if (item.isRedemption) {
						retrieved.isRedemption = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU) retrieved.groupTo = GROUP_ID.WITH_MH;
					}
					if (item.isPopularDestination) {
						retrieved.isPopularDestination = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU && direction === 'to') retrieved.groupTo = GROUP_ID.POPULAR_DEST;
					}
				} else {
					// new entry
					let groupTo = '';

					if (direction === 'to' && item.isPopularDestination) {
						groupTo = GROUP_ID.POPULAR_DEST;
					} else {
						groupTo = item.isRedemption ? GROUP_ID.WITH_MH : GROUP_ID.CODESHARE;
					}

					let displacement = -1;

					// Group by 'Near You'
					if (direction === 'from') {
						if (userPosition) {
							// geolocation available, group by GPS
							displacement = getDisplacementInKilometer(userPosition, {
								latitude: item.latitude,
								longitude: item.longitude,
							});

							if (displacement >= 0 && displacement <= 75) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						} else {
							// no geolocation, group by country
							if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						}
					}

					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
						displacement,
					});
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				direction === 'from' ? [GROUP_ID.NEAR_YOU, 'groupTo'] : null,
				direction === 'to' ? [GROUP_ID.POPULAR_DEST, 'groupTo'] : null,
				[GROUP_ID.WITH_AMAL, 'groupTo'],
				[GROUP_ID.WITH_MH, 'groupTo'],
				[GROUP_ID.CODESHARE, 'groupTo'],
				[GROUP_ID.EMPTY_LABEL, true],
			]);

			sortNearYou_MUTATE(result);
			break;
		}
		case 'FLIGHT_SEARCH_AND_HOTEL': {
			const listMapByAirportCode = new Map();

			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isMhHoliday = item.isMhHoliday;

				if (!isMhHoliday) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					const retrieved = listMapByAirportCode.get(airportCode);
					
					if (item.isRedemption) {
						retrieved.isRedemption = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU) retrieved.groupTo = GROUP_ID.WITH_MH;
					}
					if (item.isPopularDestination) {
						retrieved.isPopularDestination = true;
						if (retrieved.groupTo !== GROUP_ID.NEAR_YOU && direction === 'to') retrieved.groupTo = GROUP_ID.POPULAR_DEST;
					}
				} else {
					// new entry
					let groupTo = '';

					if (direction === 'to' && item.isPopularDestination) {
						groupTo = GROUP_ID.POPULAR_DEST;
					} else {
						groupTo = item.isRedemption ? GROUP_ID.WITH_MH : GROUP_ID.CODESHARE;
					}
					
					let displacement = -1;
					
					// Group by 'Near You'
					if (direction === 'from') {
						if (userPosition) {
							// geolocation available, group by GPS
							displacement = getDisplacementInKilometer(userPosition, {
								latitude: item.latitude,
								longitude: item.longitude,
							});

							if (displacement >= 0 && displacement <= 75) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						} else {
							// no geolocation, group by country
							if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						}
					}

					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
						displacement,
					});
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				direction === 'from' ? [GROUP_ID.NEAR_YOU, 'groupTo'] : null,
				direction === 'to' ? [GROUP_ID.POPULAR_DEST, 'groupTo'] : null,
				[GROUP_ID.WITH_MH, 'groupTo'],
				[GROUP_ID.CODESHARE, 'groupTo'],
				[GROUP_ID.EMPTY_LABEL, true],
			]);

			sortNearYou_MUTATE(result);
			break;
		}
		case 'FLIGHT_STATUS': {
			const listMapByAirportCode = new Map();

			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isFlightStatus = item.isFlightStatus;

				if (!isFlightStatus) return;

				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					// does nothing
				} else {
					// new entry
					listMapByAirportCode.set(airportCode, item);
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				[GROUP_ID.WITH_MH, true],
			]);
			break;
		}
		case 'INFLIGHT_MENU': {
			const listMapByAirportCode = new Map();

			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isRedemption = item.isRedemption;

				if (!isRedemption) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					// does nothing
				} else {
					// new entry
					listMapByAirportCode.set(airportCode, item);
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				[GROUP_ID.WITH_MH, true],
			]);
			
			break;
		}
		case 'FLIGHT_SCHEDULE': {
			const listMapByAirportCode = new Map();

			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isRevenue = item.isRevenue;

				if (!isRevenue) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
					const retrieved = listMapByAirportCode.get(airportCode);
					
					if (item.isRedemption) {
						retrieved.isRedemption = true;
						retrieved.groupTo = GROUP_ID.WITH_MH;
					}
				} else {
					// new entry
					let groupTo = '';
					
					groupTo = item.isRedemption ? GROUP_ID.WITH_MH : GROUP_ID.CODESHARE;
					
					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
					});
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				[GROUP_ID.WITH_MH, 'groupTo'],
				[GROUP_ID.CODESHARE, 'groupTo'],
				[GROUP_ID.EMPTY_LABEL, true],
			]);
			
			break;
		}
		case 'NEWSLETTER_SIGNUP': {
			const listMapByAirportCity = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;
				const airportCity = item.city;
				const isNewsletter = item.isNewsletter;

				if (!isNewsletter) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCity.has(airportCity)) {
					// already exist in the mapping
					// do nothing
				} else {
					// new entry
					listMapByAirportCity.set(airportCity, item);
				}
			});

			result = Array.from(listMapByAirportCity.values());
			
			break;
		}
		case 'TRIPS_AND_TOURS': {
			const listMapByAirportCode = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isMhHoliday = item.isMhHoliday;

				if (!isMhHoliday) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (listMapByAirportCode.has(airportCode)) {
					// already exist in the mapping
				} else {
					// new entry
					let displacement = -1;
					let groupTo = '';
					
					// Group by 'Near You'
					if (direction === 'from') {
						if (userPosition) {
							// geolocation available, group by GPS
							displacement = getDisplacementInKilometer(userPosition, {
								latitude: item.latitude,
								longitude: item.longitude,
							});

							if (displacement >= 0 && displacement <= 75) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						} else {
							// no geolocation, group by country
							if (item.countryCode?.toUpperCase() === currentPageCountry.toUpperCase()) {
								groupTo = GROUP_ID.NEAR_YOU;
							}
						}
					}

					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
						displacement,
					});
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				direction === 'from' ? [GROUP_ID.NEAR_YOU, 'groupTo'] : null,
				[GROUP_ID.EMPTY_LABEL, true],
			]);

			sortNearYou_MUTATE(result);
			
			break;
		}
		case 'AIRPORT_TRANSFER': {
			const listMapByAirportCode = new Map();
			
			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isMhHoliday = item.isMhHoliday;
				
				if (!isMhHoliday) return;
				
				if (Array.isArray(excludeList) &&
					excludeList.includes(airportCode)) return;
					
				
				if (listMapByAirportCode.has(airportCode)) {
					// exist in map set, do nothing.
					
				} else {
					// set new value in map
					const groupTo = item.isPopularDestination ? GROUP_ID.POPULAR_DEST : GROUP_ID.EMPTY_LABEL;
					
					listMapByAirportCode.set(airportCode, {
						...item,
						groupTo,
					});
				}
				
			});

			result = Array.from(listMapByAirportCode.values());
			
			result = processDataListIntoGroups(result, [
				[GROUP_ID.POPULAR_DEST, 'groupTo'],
				[GROUP_ID.EMPTY_LABEL, true],
			]);
			
			break;
		}
		case 'FIREFLY_AIR_CARGO_SEARCH': {
			const listMapByAirportCode = new Map();

			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isAirCargo = item.isAircargo;
				const isRevenue = item.isRevenue;

				if (!isRevenue) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (!listMapByAirportCode.has(airportCode)) {
					// new entry
					let groupTo = '';

					if (isAirCargo) {
						listMapByAirportCode.set(airportCode, {
							...item,
							groupTo,
						});
					}
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				[GROUP_ID.EMPTY_LABEL, true],
			]);

			break;
		}
		case 'FIREFLY_CHECK_IN': {
			const listMapByAirportCode = new Map();

			result.forEach((item) => {
				const airportCode = item.airportCode;
				const isCheckin = item.isCheckin;
				const isRevenue = item.isRevenue;

				if (!isRevenue) return;
				if (Array.isArray(excludeList) && excludeList.includes(airportCode)) return;

				if (!listMapByAirportCode.has(airportCode)) {
					// new entry
					let groupTo = '';

					if (isCheckin) {
						listMapByAirportCode.set(airportCode, {
							...item,
							groupTo,
						});
					}
				}
			});

			result = Array.from(listMapByAirportCode.values());

			result = processDataListIntoGroups(result, [
				[GROUP_ID.EMPTY_LABEL, true],
			]);

			break;
		}
		default: {
			break;
		}
	}
	
	return result;
};

export const useONDList = ({ fromValueRef, toValueRef, listIdentifier, emitOptionChange, translationLocale } = {}) => {
	const instance = getCurrentInstance();

	if (!hasInitiated) { hasInitiated = true; init(); }

	if (!hasInitiated_LocaleTranslation && translationLocale) {
		hasInitiated_LocaleTranslation = true;
		currentSiteLocale.value = translationLocale;
		init();
	}

	const isBST = computed(() => {
		return unref(listIdentifier)?.endsWith('_BST');
	});
	
	const internalIsLoadingFrom = ref(false);
	const internalIsLoadingTo = ref(false);
	
	const computedIsLoadingFrom = computed(() => {
		isBaseListLoading.value; // eslint-disable-line
		if (internalIsLoadingFrom.value) return true;
		if (isBaseListLoading.value) return true;
		return false;
	});
	const computedIsLoadingTo = computed(() => {
		isBaseListLoading.value; // eslint-disable-line
		if (internalIsLoadingTo.value) return true;
		if (isBaseListLoading.value) return true;
		return false;
	});
	
	const firstSelection = ref(null);
	const listFrom = ref(null);
	const listTo = ref(null);
	
	const computedOptionsFrom = computed(() => {
		const { pageProperties } = useGlobalAEMState();
		const currentPageCountry = pageProperties.value.rootCountry;
		
		let result = [];
		
		reactiveUserGeolocation.value; // eslint-disable-line
		baseList.value; // eslint-disable-line
		baseList_BST.value; // eslint-disable-line
		
		let dataList = [];
		// determine the dataList
		if (listFrom.value) {
			dataList = listFrom.value;
		} else {
			if (unref(isBST)) {
				// BST
				if (baseList_BST.value?.originList) {
					dataList = baseList_BST.value.originList;
				}
			} else {
				// non-BST
				if (unref(listIdentifier) === 'FLIGHT_SEARCH_OW_REDEMPTION' && oWAirportList.value?.originList) {
					dataList = oWAirportList.value.originList;
				}
				if (unref(listIdentifier) !== 'FLIGHT_SEARCH_OW_REDEMPTION' && baseList.value?.originList) {
					dataList = baseList.value.originList;
				}
			}
		}
		result = processDatalist(dataList, {
			isBST: unref(isBST),
			listIdentifier: unref(listIdentifier),
			direction: 'from',
			excludeList: unref(toValueRef) ? [unref(toValueRef)] : null,
			userCoords: userCoords.value,
			currentPageCountry,
		});
		return result;
	});

	const computedOptionsTo = computed(() => {
		const { pageProperties } = useGlobalAEMState();
		const currentPageCountry = pageProperties.value.rootCountry;
		
		baseList.value; // eslint-disable-line
		baseList_BST.value; // eslint-disable-line
		
		let result = [];
		let dataList = [];

		if (listTo.value) {
			dataList = listTo.value;
		} else {
			if (unref(isBST)) {
				// BST
				if (baseList_BST.value?.destinationList) {
					dataList = baseList_BST.value.destinationList;
				}
			} else {
				// non-BST
				if (unref(listIdentifier) === 'FLIGHT_SEARCH_OW_REDEMPTION' && oWAirportList.value?.destinationList) {
					dataList = oWAirportList.value.destinationList;
				}
				if (unref(listIdentifier) !== 'FLIGHT_SEARCH_OW_REDEMPTION' && baseList.value?.destinationList) {
					dataList = baseList.value.destinationList;
				}
			}
		}
		
		result = processDatalist(dataList, {
			isBST: unref(isBST),
			listIdentifier: unref(listIdentifier),
			direction: 'to',
			excludeList: unref(fromValueRef) ? [unref(fromValueRef)] : null,
			userCoords: false,
			currentPageCountry,
		});
		
		return result;
	});
	
	const computedOptionsFromFlattened = computed(() => {
		return computedOptionsFrom.value.reduce((acc, item) => {
			if (item.options) {
				acc.push(...item.options);
			} else {
				acc.push(item);
			}
			return acc;
		}, []);
	});
	const computedOptionsToFlattened = computed(() => {
		return computedOptionsTo.value.reduce((acc, item) => {
			if (item.options) {
				acc.push(...item.options);
			} else {
				acc.push(item);
			}
			return acc;
		}, []);
	});
	
	const updateFromValue = async () => {
		const waitForInitComplete = waitFor(() => hasInitCompleted === true);
		await waitForInitComplete.start();
	
		if (!fromValueRef.value && !toValueRef.value) {
			firstSelection.value = null;
			listFrom.value = null;
			listTo.value = null;
			return;
		}
		if (firstSelection.value === 'to') {
			// ---- If value ref is a string, convert it to Object by finding the corresponding item ----
			if (typeof fromValueRef.value === 'string') {
				const foundItem = computedOptionsFromFlattened.value.find((item) => item.code === fromValueRef.value);
				if (foundItem) {
					fromValueRef.value = foundItem;
				} else {
					console.warn(`useONDList.js: Invalid from(origin) string value of "${fromValueRef.value}"`);
				}
			}
			// ---- / END If value ref is a string, convert it to Object by finding the corresponding item ----
			return;
		}
		if (!listTo.value) firstSelection.value = 'from';
		if (!fromValueRef.value) {
			if (firstSelection.value === 'from') {
				toValueRef.value = null;
				firstSelection.value = null;
				listTo.value = null;
			}
			return;
		}
		
		listTo.value = null;
		internalIsLoadingTo.value = true;
		if (unref(listIdentifier) === 'FLIGHT_SEARCH_OW_REDEMPTION') {
			listTo.value = filterAirportBasedOnUserSelection(fromValueRef.value.airportCode, oWAirportList.value.destinationList);
			internalIsLoadingTo.value = false;
		} else {
			const fetchService = await getFetchService({ isBST: isBST.value });
			try {
				const responseData = await fetchService.fetchToList(fromValueRef.value?.code ?? fromValueRef.value);
				listTo.value = responseData;
			} catch (err) {
				console.warn(`fetchToList API error`, err);
			} finally {
				internalIsLoadingTo.value = false;
			}
		}
		
		if (typeof fromValueRef.value === 'string' && fromValueRef.value.length !== 3) return;

		// ---- If value ref is a string, convert it to Object by finding the corresponding item ----
		if (typeof fromValueRef.value === 'string') {
			const foundItem = computedOptionsFromFlattened.value.find((item) => item.code === fromValueRef.value);
			if (foundItem) {
				fromValueRef.value = foundItem;
			} else {
				console.warn(`useONDList.js: Invalid from(origin) string value of "${fromValueRef.value}"`);
			}
		}
		// ---- / END If value ref is a string, convert it to Object by finding the corresponding item ----
	};
	const updateToValue = async () => {
		const waitForInitComplete = waitFor(() => hasInitCompleted === true);
		await waitForInitComplete.start();
		
		if (!fromValueRef.value && !toValueRef.value) {
			listFrom.value = null;
			listTo.value = null;
			return;
		}
		if (firstSelection.value === 'from') {
			// ---- If value ref is a string, convert it to Object by finding the corresponding item ----
			if (typeof toValueRef.value === 'string') {
				const foundItem = computedOptionsToFlattened.value.find((item) => item.code === toValueRef.value);
				if (foundItem) {
					toValueRef.value = foundItem;
				} else {
					console.warn(`useONDList.js: Invalid to(destination) string value of "${toValueRef.value}"`);
				}
			}
			// ---- / END If value ref is a string, convert it to Object by finding the corresponding item ----
			return;
		}
		if (!listFrom.value) firstSelection.value = 'to';
		if (!toValueRef.value) {
			if (firstSelection.value === 'to') {
				fromValueRef.value = null;
				firstSelection.value = null;
				listFrom.value = null;
			}
			return;
		}
		
		listFrom.value = null;
		internalIsLoadingFrom.value = true;
		if (unref(listIdentifier) === 'FLIGHT_SEARCH_OW_REDEMPTION') {
			listFrom.value = filterAirportBasedOnUserSelection(toValueRef.value.airportCode, oWAirportList.value.originList);
			internalIsLoadingFrom.value = false;
		} else {
			const fetchService = await getFetchService({ isBST: isBST.value });
			try {
				const responseData = await fetchService.fetchFromList(toValueRef.value.airportCode, toValueRef.value?.code ?? toValueRef.value);
				listFrom.value = responseData;
			} catch (err) {
				console.warn(`fetchFromList API error`, err);
			} finally {
				internalIsLoadingFrom.value = false;
			}
		}
		
		// ---- If value ref is a string, convert it to Object by finding the corresponding item ----
		if (typeof toValueRef.value === 'string') {
			const foundItem = computedOptionsToFlattened.value.find((item) => item.code === toValueRef.value);
			console.log('toValueRef foundItem = ', foundItem);
			if (foundItem) {
				toValueRef.value = foundItem;
			} else {
				console.warn(`useONDList.js: Invalid to(destination) string value of "${toValueRef.value}"`);
			}
		}
		// ---- / END If value ref is a string, convert it to Object by finding the corresponding item ----
	};

	const filterAirportBasedOnUserSelection = (selectedAirportCode, airportList) => {
		const airports = [];
		if (!selectedAirportCode || !Array.isArray(airportList)) {
			return airports;
		}
	
		return airportList.filter((airport) => airport.airportCode !== selectedAirportCode);
	};
	
	const reactiveUserGeolocation = ref(null);
	
	const setUserGeolocation = (userGeolocation) => {
		reactiveUserGeolocation.value = userGeolocation;
	};
	
	const userCoords = computed(() => {
		if (!reactiveUserGeolocation.value) return null;
		if (unref(reactiveUserGeolocation.value.error)) return null;
		return reactiveUserGeolocation.value.coords;
	});
	
	const swapLocations = async () => {
		if (internalIsLoadingFrom.value || internalIsLoadingTo.value) return false;
		
		if (!fromValueRef.value && !toValueRef.value) {
			return false;
		} else if (!toValueRef.value) {
			// to empty, move from -> to
			firstSelection.value = 'to';
			[fromValueRef.value, toValueRef.value] = [toValueRef.value, fromValueRef.value];
			
			listTo.value = null;
			listFrom.value = null;
			
			// check new value of to for eligibility
			const foundItem = computedOptionsToFlattened.value.find((item) => item.value === toValueRef.value.value);
			toValueRef.value = foundItem || null;
			if (toValueRef.value === null) return;
			
			internalIsLoadingFrom.value = true;
			if (unref(listIdentifier) === 'FLIGHT_SEARCH_OW_REDEMPTION') {
				listFrom.value = filterAirportBasedOnUserSelection(toValueRef.value.airportCode, oWAirportList.value.originList);
				internalIsLoadingFrom.value = false;
			} else {
				const fetchService = await getFetchService({ isBST: isBST.value });
				try {
					const responseData = await fetchService.fetchFromList(toValueRef.value?.code ?? toValueRef.value);
					listFrom.value = responseData;
				} catch (err) {
					console.warn(`fetchFromList API error`, err);
				} finally {
					internalIsLoadingFrom.value = false;
				}
			}
		} else if (!fromValueRef.value) {
			// from empty, move to -> from
			firstSelection.value = 'from';
			[fromValueRef.value, toValueRef.value] = [toValueRef.value, fromValueRef.value];
			
			listFrom.value = null;
			listTo.value = null;
			
			// check new value of from for eligibility
			const foundItem = computedOptionsFromFlattened.value.find((item) => item.value === fromValueRef.value.value);
			fromValueRef.value = foundItem || null;
			
			if (fromValueRef.value === null) return;
			
			internalIsLoadingTo.value = true;
			if (unref(listIdentifier) === 'FLIGHT_SEARCH_OW_REDEMPTION') {
				listTo.value = filterAirportBasedOnUserSelection(fromValueRef.value.airportCode, oWAirportList.value.destinationList);
				internalIsLoadingTo.value = false;
			} else {
				const fetchService = await getFetchService({ isBST: isBST.value });
				try {
					const responseData = await fetchService.fetchToList(fromValueRef.value?.code ?? fromValueRef.value);
					listTo.value = responseData;
					if (toValueRef.value) {
						const foundItem = computedOptionsToFlattened.value.find((item) => item.value === toValueRef.value.value);
						toValueRef.value = foundItem || null;
					}
				} catch (err) {
					console.warn(`fetchToList API error`, err);
				} finally {
					internalIsLoadingTo.value = false;
				}
			}
		} else {
			// both have values
			[fromValueRef.value, toValueRef.value] = [toValueRef.value, fromValueRef.value];
			
			listFrom.value = null;
			listTo.value = null;
			
			// check new value of from for eligibility
			const foundItem = computedOptionsFromFlattened.value.find((item) => item.value === fromValueRef.value.value);
			fromValueRef.value = foundItem || null;
			
			if (fromValueRef.value === null) {
				firstSelection.value = 'to';
				
				internalIsLoadingFrom.value = true;
				if (unref(listIdentifier) === 'FLIGHT_SEARCH_OW_REDEMPTION') {
					listFrom.value = filterAirportBasedOnUserSelection(toValueRef.value.airportCode, oWAirportList.value.originList);
					internalIsLoadingFrom.value = false;
				} else {
					const fetchService = await getFetchService({ isBST: isBST.value });
					try {
						const responseData = await fetchService.fetchFromList(toValueRef.value?.code ?? toValueRef.value);
						listFrom.value = responseData;
					} catch (err) {
						console.warn(`fetchToList API error`, err);
					} finally {
						internalIsLoadingFrom.value = false;
					}
				}
			} else {
				firstSelection.value = 'from';
				
				internalIsLoadingTo.value = true;
				
				if (unref(listIdentifier) === 'FLIGHT_SEARCH_OW_REDEMPTION') {
					listTo.value = filterAirportBasedOnUserSelection(fromValueRef.value.airportCode, oWAirportList.value.destinationList);
					internalIsLoadingTo.value = false;
				} else {
					const fetchService = await getFetchService({ isBST: isBST.value });
					try {
						const responseData = await fetchService.fetchToList(fromValueRef.value?.code ?? fromValueRef.value);
						listTo.value = responseData;
						const foundItem = computedOptionsToFlattened.value.find((item) => item.value === toValueRef.value.value);
						toValueRef.value = foundItem || null;
					} catch (err) {
						console.warn(`fetchToList API error`, err);
					} finally {
						internalIsLoadingTo.value = false;
					}
				}
			}
		}
		
		
	};
	
	return {
		optionsFrom: computedOptionsFrom,
		optionsTo: computedOptionsTo,

		optionsFromFlattened: computedOptionsFromFlattened,
		optionsToFlattened: computedOptionsToFlattened,
		
		isLoadingFrom: computedIsLoadingFrom,
		isLoadingTo: computedIsLoadingTo,
		
		setUserGeolocation,
		swapLocations,
		
		updateFromValue,
		updateToValue,
	};
};

export const getMalaysiaAirportForBST = async ({ from, to } = {}) => {
	if (!from || !to) throw new Error('Missing from or to');
	if (!hasInitiated) { hasInitiated = true; init(); }
	const fetchService = await getFetchService({ isBST: true });
	
	const result = {
		fromTo: null,
		toFrom: null,
	};
	
	try {
		const responseData = await fetchService.fetchToList(from);
		const toDomesticAirports = responseData.find((item) => {
			return item.airportCode === to;
		});
		if (!toDomesticAirports) {
			throw new Error(`Could not find matching "to" airport.`);
		}
		result.fromTo = toDomesticAirports.domesticDestinations.map(appendAirportInfo);
	} catch (err) {
		console.warn(`getMalaysiaAirportForBST fetchToList API error`, err);
		throw new Error(err);
	}

	try {
		const responseData = await fetchService.fetchFromList(to);
		const fromDomesticAirports = responseData.find((item) => {
			return item.airportCode === from;
		});
		if (!fromDomesticAirports) {
			throw new Error(`Could not find matching "from" airport.`);
		}
		result.toFrom = fromDomesticAirports.domesticDestinations.map(appendAirportInfo);
	} catch (err) {
		console.warn(`getMalaysiaAirportForBST fetchFromList API error`, err);
		throw new Error(err);
	}
	
	return result;
};

const getDropdownONDListUrl = (siteName) => {
	switch (siteName) {
		case 'amal':
			return unref(useGlobalAEMState().pageProperties).amalDropDownONDListUrl;
		case 'firefly':
			return unref(useGlobalAEMState().pageProperties).fireflyDropDownONDListUrl;
		default:
			return unref(useGlobalAEMState().pageProperties).newDropDownONDListUrl;
	}
};
