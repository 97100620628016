<script>
export default {
	name: 'DateRangePicker',
};
</script>

<script setup>
import yup from 'mh-yup';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import MHCalendarDouble from '~/components/DatePicker/MHCalendarDouble.vue';
import TextField from '~/components/form/TextField.vue';
import useInternalValue from '~/logic/composables/useInternalValue.js';
import OverlayBtmSticky from '~/components/OverlayBtmSticky.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { MHScrollToElement, isElOutOfViewport } from '~/logic/helpers/dom.js';
import { parseSpecialDateSyntaxToDayjs } from '~/logic/composables/useCalendarRestrictions.js';

dayjs.extend(isBetween);


const props = defineProps({
	nameFrom: { type: String, required: true },
	nameTo: { type: String, required: true },

	modelValueFrom: { type: String, default: null },
	modelValueTo: { type: String, default: null },
	
	textFieldAttrsFrom: { type: [Object], default: () => ({
		placeholder: i18nGlobal.t('Select a date'),
		ariaLabel: i18nGlobal.t('From'),
	}) },
	textFieldAttrsTo: { type: [Object], default: () => ({
		placeholder: i18nGlobal.t('Select a date'),
		ariaLabel: i18nGlobal.t('To'),
	}) },
	
	// restrictions
	/* 
	minDate / maxDate prop:
		· Can support absolute date or relative date
		
		Absolute dates
		· Format is 'YYYY-MM-DD'
		E.g. '2022-11-26'
		
		Relative dates
		· t = today
		· Only support + / -
		· No whitespace allowed.
		· Must end with d / m / y
		E.g.
			't-10d' <-- today minus 10 days
			't-2m' <-- today minus 2 months
			't+1y' <-- today plus 1 year
	*/
	minDate: { type: String, default: null },
	maxDate: { type: String, default: null },
	
	// disabledDates iterates over the Array for every date. That is slow, avoid if possible.
	disabledDates: { type: Array, default: null },
	
	// disablePastDates will be overwritten by minDate
	disablePastDates: { type: Boolean, default: false },
	// disableFutureDates will be overwritten by maxDate
	disableFutureDates: { type: Boolean, default: false },
	disableCalendarPickerAnimation: { type: Boolean, default: false },
	
	/* 
		minDateFromAfterSelectingTo prop:
			· Minimum date for "from" picker when "to" picker already has a value
			· Can be relative date, where
				· t = selected date of "to" picker
			· Supports + / -, see examples for min/max date prop
	*/
	minDateFromAfterSelectingTo: { type: String, default: 't' },
	maxDateFromAfterSelectingTo: { type: String, default: null },
	
	requiredFrom: { type: Boolean, default: false },
	requiredTo: { type: Boolean, default: false },
	
	requiredErrorMsgFrom: { type: String, default: i18nGlobal.t('This field is required') },
	requiredErrorMsgTo: { type: String, default: i18nGlobal.t('This field is required') },
	
	priceData: { type: Object, default: null },
	
	isHideTodayIndicator: { type: Boolean, default: false },
	
	defaultIsOneWay: { type: Boolean, default: false },
	forceOneWay: { type: Boolean, default: false },

	labelReset: { type: String, default: undefined }, // pass-through prop, undefined as default
	labelDone: { type: String, default: undefined }, // pass-through prop, undefined as default
	labelOneWay: { type: String, default: undefined }, // pass-through prop, undefined as default
	
	customValidationDatepickerFrom: { type: Function, default: null },
	hideOneWay: { type: Boolean, default: false },
});
const emit = defineEmits([
	'update:modelValueFrom',
	'update:modelValueTo',
	'update:isOneWay',
]);

const siteName = window.siteName;

const isShowFloatingPanel = ref(false);
const rootEl = ref(null);
const floatingPanelEl = ref(null);
const isFloatingPanelOnRight = ref(false);
const handleRootFocusedWithin = () => {
	// showFloatingPanel();
};
const handleRootBlurredWithin = () => {
	if (isMobileViewport.value) return;
	hideFloatingPanel();
};

const showFloatingPanel = async (triggeringInput) => {
	if (isMobileViewport.value) {
		// mobile
		setShowPickerOverlay(true);
		return;
	}
	// desktop
	if (
		triggeringInput !== 'from'
		&& triggeringInput.target.firstChild?.classList?.contains('TextField')
		&& isShowFloatingPanel.value
	) {
		hideFloatingPanel();
		return;
	}
	if (isShowFloatingPanel.value) return;
	isShowFloatingPanel.value = true;
	// decide which text field is the active one
	if (internalValueFrom.value && !internalValueTo.value) {
		activeTextField.value = isOneWay.value ? 'from' : 'to';
	} else {
		activeTextField.value = 'from';
	}
	
	await nextTick();
	const isOut = isElOutOfViewport(floatingPanelEl.value);
	if (isOut.any) {
		if (isOut.right) {
			isFloatingPanelOnRight.value = true;
		} else if (isOut.left) {
			isFloatingPanelOnRight.value = false;
		}
	}

	// Implement auto-scrolling and focus on booking-widget when user interacting with the AirportPicker & CalendarPicker.
	// Only works when both conditions are met:
	// 1.) desktop-view & 2.) booking-widget without sticky-styling
	const isBookingWidget_Sticky = document.querySelector('.BookingWidget .SwitchTabContent').classList.contains('sticked');
	if (!isMobileViewport.value && !isBookingWidget_Sticky) {
		MHScrollToElement(document.querySelector('.ONDPicker'), { additionalTopOffset: 160 });
	}
};
const hideFloatingPanel = () => {
	if (isMobileViewport.value) {
		// mobile
		setShowPickerOverlay(false);
		activeTextField.value = '';
		return;
	}
	
	// desktop
	if (!isShowFloatingPanel.value) return;
	isShowFloatingPanel.value = false;
	activeTextField.value = '';
	
	/* 
		Evaluate if internalValueFrom is within the view of calendarViewDayJs,
		if not, then forget about calendarViewDayJs (set it to null).
		Reason is because we want our UI to be user-friendly,
		Let's say user navigates to an arbitrary date, in the far future, then when calendar was closed and reopened,
		we want user to be in the view of their current selections.
	*/
	if (internalValueFrom.value === null) {
		// haven't select any value at all, I decided that resetting the view is the better behaviour
		calendarViewDayJs.value = null;
	} else if (internalValueFrom.value && calendarViewDayJs.value) {
		// has a value selected already, check is in current view or not
		const viewDate = calendarViewDayJs.value.date(1); // we only care about month, set the date to 1
		if (!internalValueFrom.value.isBetween(viewDate, viewDate.add(2, 'month'), '[]')) {
			calendarViewDayJs.value = null;
		}
	}
};

const handleRootKeyEsc = (event) => {
	hideFloatingPanel();
};

const showPickerOverlay = ref(false);

const setShowPickerOverlay = (flag) => {
	showPickerOverlay.value = flag;
};


const internalValueFrom = useInternalValue('modelValueFrom', {
	beforeEmitTransform (value) {
		if (value instanceof dayjs) return value.format('YYYY-MM-DD');
		return null;
	},
	propReceivedTransform (value) {
		const transformed = dayjs(value);
		if (!transformed.isValid()) {
			return null;
		}
		return transformed;
	},
});
const internalValueTo = useInternalValue('modelValueTo', {
	beforeEmitTransform (value) {
		if (value instanceof dayjs) return value.format('YYYY-MM-DD');
		return null;
	},
	propReceivedTransform (value) {
		const transformed = dayjs(value);
		if (!transformed.isValid()) {
			return null;
		}
		return transformed;
	},
});


const calendarEl = ref(null);
const inputElFrom = ref(null);
const inputElTo = ref(null);

const activeTextField = ref(''); // <-- 'from' | 'to'


const computedMinDate = computed(() => {
	props.minDate; // eslint-disable-line
	props.disablePastDates; // eslint-disable-line
	
	if (activeTextField.value === 'to') {
		// return internalValueFrom.value.format('YYYY-MM-DD');
		if (props.minDateFromAfterSelectingTo && internalValueFrom.value) {
			return parseSpecialDateSyntaxToDayjs(
				internalValueFrom.value,
				props.minDateFromAfterSelectingTo,
				't',
			)?.format('YYYY-MM-DD') ?? null;
		}
	}
	if (props.minDate) return props.minDate;
	if (props.disablePastDates) return 't';
	return null;
});
const computedMaxDate = computed(() => {
	props.maxDate; // eslint-disable-line
	props.disableFutureDates; // eslint-disable-line

	if (activeTextField.value === 'to') {
		// return internalValueFrom.value.format('YYYY-MM-DD');
		if (props.maxDateFromAfterSelectingTo && internalValueFrom.value) {
			return parseSpecialDateSyntaxToDayjs(
				internalValueFrom.value,
				props.maxDateFromAfterSelectingTo,
				't',
			)?.format('YYYY-MM-DD') ?? null;
		}
	}
	if (props.maxDate) return props.maxDate;
	if (props.disableFutureDates) return 't';
	return null;
});

const handleSelectDate = (dayjsInstance) => {
	if (dayjsInstance === null) {
		handleResetDates();
		return;
	}
	
	if (!(dayjsInstance instanceof dayjs)) {
		console.warn(`<DateRangePicker> expects null or a dayjsInstance when selecting date, but instead got "${dayjsInstance}". This is a no-op. Check emit logic.`);
		return;
	}
	
	let whichWay = '';
	
	if (internalValueFrom.value && internalValueTo.value) {
		whichWay = 'from';
		internalValueTo.value = null;
	} else if (!internalValueFrom.value && !internalValueTo.value) {
		whichWay = 'from';
	} else {
		whichWay = isOneWay.value ? 'from' : 'to';
	}
	
	if (whichWay === 'from') {
		internalValueFrom.value = dayjsInstance;
		if (!isOneWay.value) activeTextField.value = 'to';
	} else {
		internalValueTo.value = dayjsInstance;
		activeTextField.value = 'from';
	}
};

const handleResetDates = () => {
	internalValueFrom.value = null;
	internalValueTo.value = null;
	activeTextField.value = 'from';
	
	inputElFrom.value?.setTouched(false);
	inputElTo.value?.setTouched(false);
	
	if (!props.forceOneWay) isOneWay.value = false;
};

const activeHoverTextField = computed(() => {
	if (internalValueFrom.value && internalValueTo.value) {
		return 'from';
	}
	if (internalValueFrom.value && !internalValueTo.value) {
		return isOneWay.value ? 'from' : 'to';
	}
	return 'from';
});

const validationFrom = (value) => {
	let yupSchema = yup.string().nullable();
	if (props.requiredFrom) yupSchema = yupSchema.required(props.requiredErrorMsgFrom);
	try {
		yupSchema.validateSync(value);
		
		if (props.customValidationDatepickerFrom) {
			return props.customValidationDatepickerFrom(value);
		}
		
		return true;
	} catch (err) {
		return err.errors[0];
	}
};

const validationTo = (value) => {
	if (isOneWay.value) return true;
	let yupSchema = yup.string().nullable();
	if (props.requiredTo) yupSchema = yupSchema.required(props.requiredErrorMsgTo);
	try {
		yupSchema.validateSync(value);
		return true;
	} catch (err) {
		return err.errors[0];
	}
};


const isOneWay = ref(props.forceOneWay ?? props.defaultIsOneWay);

const handleSetIsOneWay = (flag) => {
	emit('update:isOneWay', flag);
	isOneWay.value = flag;
	if (flag) {
		activeTextField.value = 'from';
		internalValueTo.value = null;
	} else {
		if (internalValueFrom.value) {
			activeTextField.value = 'to';
		}
	}
	inputElTo.value?.validate();
};




const handleInputMounted = (refEl) => {
	unref(refEl).inputEl._mh_invalidFocus = (el) => {
		MHScrollToElement(rootEl.value, { additionalTopOffset: 60 });
		rootEl.value.focus({ preventScroll: true });
	};
};

const calendarViewDayJs = ref(null);

watch(() => [props.modelValueFrom, props.modelValueTo], (newValue, oldValue) => {
	if (!props.modelValueFrom && !props.modelValueTo) {
		activeTextField.value = 'from';
	} else if (!props.modelValueFrom) {
		activeTextField.value = 'from';
	} else if (!props.modelValueTo) {
		activeTextField.value = 'to';
	}
});


defineExpose({
	handleSetIsOneWay,

	isOneWay,
	inputElFrom,
	inputElTo,
});


</script>

<template>
<div
	ref="rootEl"
	v-focus-within
	class="DateRangePicker"
	:class="{
		'is-one-way': isOneWay,
	}"
	:data-use-theme="siteName"
	tabindex="-1"
	@focus-within="handleRootFocusedWithin"
	@blur-within="handleRootBlurredWithin"
	@click="showFloatingPanel"
	@keydown.esc.prevent="handleRootKeyEsc"
>
	<div
		class="inner-wrapper"
	>
		<div class="picker-wrapper picker-wrapper-from">
			<TextField
				ref="inputElFrom"
				:rootAttrs="{
					class: {
						'textfield-from pointer-events-none': true,
						'textfield-active': activeHoverTextField === 'from',
					},
				}"
				class=""
				:class="{
					'is-active': activeTextField === 'from',
				}"
				:modelValue="internalValueFrom?.format('DD MMM YYYY') ?? null"
				:isFocused="activeTextField === 'from'"
				:showAsteriskSymbol="props.requiredFrom"
				:validation="validationFrom"
				variant="booking-widget"
				readOnly
				:name="props.nameFrom"
				v-bind="props.textFieldAttrsFrom"
				@focus="showFloatingPanel('from')"
				@click="showFloatingPanel('from')"
				@keydown.down.prevent="calendarEl.handleArrowNavigation('down')"
				@keydown.right.prevent="calendarEl.handleArrowNavigation('right')"
				@keydown.up.prevent="calendarEl.handleArrowNavigation('up')"
				@keydown.left.prevent="calendarEl.handleArrowNavigation('left')"
				@keydown.backspace.delete.prevent="handleSelectDate(null)"
				@vue:mounted="handleInputMounted(inputElFrom)"
			></TextField>
		</div>
		
		<div class="separator mx-4 2xl:mx-1 md:mx-2" aria-hidden="true"></div>
		
		<div class="picker-wrapper picker-wrapper-to">
			<TextField
				ref="inputElTo"
				:rootAttrs="{
					class: {
						'textfield-to pointer-events-none': true,
						'textfield-active': activeHoverTextField === 'to',
					},
				}"
				:class="{
					'is-active': activeTextField === 'to',
				}"
				:modelValue="internalValueTo?.format('DD MMM YYYY') ?? null"
				variant="booking-widget"
				:isFocused="activeTextField === 'to'"
				:showAsteriskSymbol="props.requiredTo"
				:name="props.nameTo"
				:validation="validationTo"
				readOnly
				v-bind="props.textFieldAttrsTo"
				tabindex="-1"
				@vue:mounted="handleInputMounted(inputElTo)"
			></TextField>
		</div>
	</div>
	
	<div
		v-if="isShowFloatingPanel && !isMobileViewport"
		ref="floatingPanelEl"
		class="floating-panel shadow-type-a border-2 border-neutral-grey-light"
		:class="{
			'!left-auto right-0': isFloatingPanelOnRight,
		}"
		role="dialog"
		aria-modal="true"
		aria-label="Choose dates"
	>
		<MHCalendarDouble
			ref="calendarEl"
			v-model:modelValueFrom="internalValueFrom"
			v-model:modelValueTo="internalValueTo"
			class=""
			:priceData="props.priceData"
			:isOneWay="isOneWay"
			:minDate="computedMinDate"
			:maxDate="computedMaxDate"
			:disabledDates="props.disabledDates"
			:disablePastDates="props.disablePastDates"
			:disableFutureDates="props.disableFutureDates"
			:defaultViewDate="calendarViewDayJs"
			:isHideTodayIndicator="props.isHideTodayIndicator"
			:forceOneWay="props.forceOneWay"
			:labelReset="props.labelReset"
			:labelDone="props.labelDone"
			:labelOneWay="props.labelOneWay"
			:hideOneWay="props.hideOneWay"
			@select-date="handleSelectDate"
			@reset-dates="handleResetDates"
			@close-panel="hideFloatingPanel"
			@set-is-one-way="handleSetIsOneWay"
			@view-date-changed="calendarViewDayJs = $event"
		>
			<template v-if="$slots['before-cta-done']" #before-cta-done>
				<slot name="before-cta-done"></slot>
			</template>
		</MHCalendarDouble>
		
	</div>
	
	<OverlayBtmSticky
		class="daterangepicker-mobile-overlay"
		:isVisible="showPickerOverlay"
		:disableCalendarPickerAnimation="props.disableCalendarPickerAnimation"
		@update:is-visible="setShowPickerOverlay($event)"
	>
		<div
			class="pl-5 sticky top-0 bg-white z-1000 pb-2 w-full"
		>
			<h6 class="max-w-[375px] px-4 mx-auto font-bold line-clamp-1">
				<slot name="mobile-title"></slot>
			</h6>
		</div>
		<div
			class="floating-panel styled-scrollbar"
			role="dialog"
			aria-modal="true"
			aria-label="Choose dates"
		>
			<MHCalendarDouble
				ref="calendarEl"
				v-model:modelValueFrom="internalValueFrom"
				v-model:modelValueTo="internalValueTo"
				class=""
				:priceData="props.priceData"
				:isOneWay="isOneWay"
				:minDate="computedMinDate"
				:maxDate="computedMaxDate"
				:disabledDates="props.disabledDates"
				:disablePastDates="props.disablePastDates"
				:disableFutureDates="props.disableFutureDates"
				:isHideTodayIndicator="props.isHideTodayIndicator"
				:forceOneWay="props.forceOneWay"
				:labelReset="props.labelReset"
				:labelDone="props.labelDone"
				:labelOneWay="props.labelOneWay"
				:hideOneWay="props.hideOneWay"
				@select-date="handleSelectDate"
				@reset-dates="handleResetDates"
				@close-panel="hideFloatingPanel"
				@set-is-one-way="handleSetIsOneWay"
			>
				<template v-if="$slots['before-cta-done']" #before-cta-done>
					<slot name="before-cta-done"></slot>
				</template>
			</MHCalendarDouble>
		</div>
	</OverlayBtmSticky>
	
	
	<div class="error-msg-container leading-tight flex flex-col text-semantic-red-base text-sm" role="alert">
		<div class="mt-1 empty:mt-0">
			<span
				v-if="(
					inputElFrom?.hasValidationError &&
					inputElFrom?.meta.touched
				)"
			>
				{{ inputElFrom?.errors[0] }}
			</span>
		</div>
		<div class="flex-1 mt-1 empty:mt-0">
			<span
				v-if="(
					inputElTo?.hasValidationError &&
					inputElTo?.meta.touched
				)"
			>
				{{ inputElTo?.errors[0] }}
			</span>
		</div>
	</div>
	
	<div class="sr-only" role="alert">
		<template v-if="!internalValueFrom && !internalValueTo">
			{{ $t('Current selection is empty') }}
		</template>
		<template v-else-if="internalValueFrom && !internalValueTo">
			{{ $t('Current selection: {dateFrom}', {
				dateFrom: internalValueFrom.format('YYYY-MM-DD'),
			}) }}
		</template>
		<template v-else-if="internalValueFrom && internalValueTo">
			{{ $t('Current selection: from {dateFrom} to {dateTo}. Navigate to the done button once you are done.', {
				dateFrom: internalValueFrom.format('YYYY-MM-DD'),
				dateTo: internalValueTo.format('YYYY-MM-DD'),
			}) }}
		</template>
	</div>
	
	<div v-if="isShowFloatingPanel" class="sr-only" role="alert">
		<template v-if="activeTextField === 'from'">
			<template v-if="isOneWay">
				{{ $t('Now selecting one-way departure date') }}
			</template>
			<template v-else>
				{{ $t('Now selecting departure date') }}
			</template>
		</template>
		<template v-else-if="activeTextField === 'to'">
			{{ $t('Now selecting return date') }}
		</template>
	</div>
</div>
</template>


<style lang="scss">
.OverlayBtmSticky.daterangepicker-mobile-overlay {
	.overlay-content {
		display: flex;
		flex-direction: column;
	}
	.floating-panel {
		position: static;
		border: 0;
	}
}
</style>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.DateRangePicker {
	--gapWidth: 56px;
	--textfield-height: 64px;
	position: relative;
	cursor: pointer;
	
	&:hover {
		:deep(.textfield-active) {
			&:not(.has-validation-error) .inner-container {
				--borderColor: var(--neutral-grey-base);
			}
			.inner-container {
				--bgColor: #{color.change(white, $alpha: 0.35)};
			}
		}
	}
	
	:deep(.TextField) {
		height: 100%;
		
		.input {
			font-weight: 600;
			color: var(--primary-blue-base);
			cursor: pointer;
		}
		.inner-wrapper {
			cursor: pointer;
		}
		
		&.textfield-active.is-focused {
			&:not(.has-validation-error) .inner-container {
				--borderColor: var(--primary-blue-base);
			}
			.inner-container {
				--bgColor: white;
			}
		}
		.error-msg-container {
			display: none;
		}
		
		@media #{var.$query-max-xs} {
			.input {
				@apply text-sm;
				height: 1.125rem; // make sure height remains 18px
			}
			.inner-wrapper {
				@apply px-3;
			}
			.text-label {
				@apply left-3;
			}
		}
	}
	:deep(.inner-container) {
		--bgColor: transparent;
		--borderColor: transparent;
	}
	
	@media #{var.$query-max-md} {
		--textfield-height: 54px;
	}
}

.inner-wrapper {
	border-radius: 12px;
	background-color: var(--neutral-grey-ultralight);
	box-shadow: 0 0 0 1.5px var(--neutral-grey-light) inset;
	position: relative;
	display: flex;
}

.picker-wrapper {
	flex: 1 1 0;
	height: var(--textfield-height);

	:deep(.text-label) {
		html[dir="rtl"] & {
			left: auto;
		}
	}
	
}

.separator {
	width: 24px;
	flex: 0 0 24px;
	align-self: center;
	height: 2px;
	background-color: var(--neutral-grey-dark);
	
	@media #{var.$query-max-md} {
		width: 12px;
		flex-basis: 12px;
	}

	@media #{var.$query-max-2xl} {
		flex: 0 0 5px;
	}
}

.floating-panel {
	position: absolute;
	overflow: hidden;
	background-color: var(--floating-calendar-bg-color, white);
	border-radius: 12px;
	z-index: 100;
	left: 0;
	top: calc(var(--textfield-height) + 4px);
	width: 84.5vw;
	max-width: 892px;
	cursor: default;
	
	@media #{var.$query-max-md} {
		width: 100%;
		max-width: none;
		overflow-y: auto;
	}
}

.DateRangePicker.is-one-way {
	
	.separator {
		display: none;
	}
	.picker-wrapper-to {
		display: none;
	}
}

.DateRangePicker[data-use-theme="MHH"] {
	
	:deep(.TextField) {
		.input {
			color: var(--primary-mhh-teal-base);
		}
	
		&.textfield-active.is-focused {
			&:not(.has-validation-error) .inner-container {
				--borderColor: var(--primary-mhh-teal-base);
			}
		}
	}
}

.DateRangePicker[data-use-theme="firefly"] {

	:deep(.TextField) {
			&.textfield-active.is-focused {
				&:not(.has-validation-error) .inner-container {
					--borderColor: var(--primary-firefly-orange-base);
				}
			}
		}
}
</style>
