<script>
export default {
	name: 'SubTabTermsConditions',
};
</script>

<script setup>

const props = defineProps({
	content: { type: String, default: '' },
});

</script>


<template>
<div class="SubTabTermsConditions styled-scrollbar overflow-y-auto mt-6 tnc-content">
	<div v-html-sanitize="props.content" class="pr-4"></div>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabTermsConditions {
	--contentHeight: 599px;
	
	&.tnc-content {
		max-height: var(--contentHeight);
		
		@media #{var.$query-max-md} {
			--contentHeight: 100%;
		};
	}
	
}

[data-use-theme="enrich-portal"] {
	.tnc-content {
		max-height: calc(100dvh - 220px);
		
		@media #{var.$query-max-md} {
			max-height: 100%;
		};
	}
	
}

</style>