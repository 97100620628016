import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import {
	setTokenSentToServer,
	isTokenSentToServer,
	setUserInfoSentToServer,
	isUserInfoSentToServer,
	setEnrichIdSentToServer,
	isEnrichIdSentToServer,
	sendClientToken,
	getBrowserOsInfo,
	getUserAgent,
	getECID,
	parseJwt,
	isUserLoggedIn,
	sendAdobeCampaignTrack,
	showMessageNotification,
	isSameToken,
	setTokenFCM,
} from '~/logic/helpers/firebase-utils.js';
import { getCookie } from '~/logic/helpers/cookies.js';
import { waitFor } from '~/logic/helpers/utils';

export const setupFirebaseCloudMessaging = () => {
	const globalAEMState = useGlobalAEMState();
	const siteName = globalAEMState.pageProperties.value.siteName;
	const siteEnv = globalAEMState.pageProperties.value.environment;

	let apiKey = globalAEMState.pageProperties.value.firebaseMH.apiKey;
	let authDomain = globalAEMState.pageProperties.value.firebaseMH.authDomain;
	let databaseURL = globalAEMState.pageProperties.value.firebaseMH.databaseURL;
	let projectId = globalAEMState.pageProperties.value.firebaseMH.projectId;
	let storageBucket = globalAEMState.pageProperties.value.firebaseMH.storageBucket;
	let messagingSenderId = globalAEMState.pageProperties.value.firebaseMH.messagingSenderId;
	let appId = globalAEMState.pageProperties.value.firebaseMH.appId;
	let measurementId = globalAEMState.pageProperties.value.firebaseMH.measurementId;
	let vapidKey = globalAEMState.pageProperties.value.firebaseMH.vapidKey;
	let swFilePath = globalAEMState.pageProperties.value.firebaseMH.swFilePath;
	let adobeIntegrationKey = globalAEMState.pageProperties.value.campaign.integrationKey;
	let adobeCampaignTrackingBaseURL = globalAEMState.pageProperties.value.campaign.trackingEndpointURL;
	const adobeCampaignTokenBaseURL = globalAEMState.pageProperties.value.campaign.tokenEndpointURL;
	if (siteName === 'firefly') {
		apiKey = globalAEMState.pageProperties.value.firebaseFirefly.apiKey;
		authDomain = globalAEMState.pageProperties.value.firebaseFirefly.authDomain;
		projectId = globalAEMState.pageProperties.value.firebaseFirefly.projectId;
		storageBucket = globalAEMState.pageProperties.value.firebaseFirefly.storageBucket;
		messagingSenderId = globalAEMState.pageProperties.value.firebaseFirefly.messagingSenderId;
		appId = globalAEMState.pageProperties.value.firebaseFirefly.appId;
		vapidKey = globalAEMState.pageProperties.value.firebaseFirefly.vapidKey;
		swFilePath = globalAEMState.pageProperties.value.firebaseFirefly.swFilePath;
		adobeIntegrationKey = globalAEMState.pageProperties.value.campaign.integrationKeyFirefly;

		if (siteEnv === 'production') {
			adobeCampaignTrackingBaseURL = globalAEMState.pageProperties.value.campaign.fireflyTrackingURL;
		}
	}
	
	// Firebase config
	const firebaseConfig = {
		apiKey,
		authDomain,
		projectId,
		storageBucket,
		messagingSenderId,
		appId,
		...(siteName !== 'firefly' ? { databaseURL } : null),
		...(siteName !== 'firefly' ? { measurementId } : null),
	};
	
	// Initialize Firebase
	const app = initializeApp(firebaseConfig);
	
	// Initialize Firebase Cloud Messaging and get a reference to the service
	const messaging = getMessaging(app);

	function requestPermission () {
		Notification.requestPermission().then(async (permission) => {
			if (permission === 'granted') {
				if ('serviceWorker' in navigator) {
					navigator.serviceWorker.register(swFilePath).then((reg) => {
						console.log(`Service Worker Registration (Scope: ${reg.scope})`);
						getToken(messaging, {
							vapidKey: vapidKey,
							serviceWorkerRegistration: reg,
						}).then((currentToken) => {
							if (currentToken) {
								console.log('currentToken:', currentToken);
								// Send the token to your server and update the UI if necessary

								sendTokenToServer(currentToken);

							} else {
								// Token error
								setTokenSentToServer(false);
							}
						}).catch((err) => {
							console.log('An error occurred while retrieving token. ', err);
							setTokenFCM('');
							setTokenSentToServer(false);
							setEnrichIdSentToServer(false);
						});
					}).catch((error) => {
						const msg = `Service Worker Error (${error})`;
						console.error(msg);
					});
				} else {
					// happens when the app isn't served over HTTPS or if the browser doesn't support service workers
					console.warn('Service Worker not available');
				}
			} else {
				console.log('Permission not granted');
			}
		});
	}

	function getUserKey () {
		const decodedToken = parseJwt(getCookie('sapphire'));

		return decodedToken?.extension_flyerid ?? '';
	}

	function sendTokenToServer (currentToken) {

		if (
			!isTokenSentToServer() ||
			!isSameToken(currentToken) ||
			(!isUserInfoSentToServer() && (getUserKey() !== '' && getUserKey() !== null))
		) {
			console.log('Sending token to server...');
			pollForDigitalData(currentToken);
			
		} else {
			console.log(`Token already sent to server so won't send it again unless it changes`);
		}
	}

	function pollForDigitalData (currentToken) {
		const devDetails = getBrowserOsInfo.init();
		const country = globalAEMState.pageProperties.value.rootCountry ?? window.location.pathname.split('/')[1];
		const lang = globalAEMState.pageProperties.value.rootLanguage ?? window.location.pathname.split('/')[2];
		// _satellite is undefined at this moment
		const wait = waitFor(() => window._satellite);
		wait.start().then(() => {
			if (isUserLoggedIn()) {
				/* logged in */
				// console.log("Token sent with user data");
	
				sendClientToken({
					clientToken: currentToken,
					baseUrl: adobeCampaignTokenBaseURL,
					acui: adobeIntegrationKey,
					userKey: getUserKey(),
					mcid: getECID(),
					language: devDetails.language,
					osName: devDetails.os.name,
					osVer: devDetails.os.version,
					browserName: devDetails.browser.name,
					browserVer: devDetails.browser.version,
					siteName: siteName,
					country: country,
					newLanguage: lang,
				})
					.then((response) => {
						setTokenFCM(currentToken);
					})
					.catch((error) => {
						setTokenFCM('');
					});
	
				setTokenSentToServer(true);
				
				if (getUserKey() !== '' && getUserKey() !== null) {
					setUserInfoSentToServer(true);
				}
			} else {
				/* not logged in */
				// console.log("Token sent without user data");
				
				sendClientToken({
					clientToken: currentToken,
					baseUrl: adobeCampaignTokenBaseURL,
					acui: adobeIntegrationKey,
					userKey: '',
					mcid: getECID(),
					language: devDetails.language,
					osName: devDetails.os.name,
					osVer: devDetails.os.version,
					browserName: devDetails.browser.name,
					browserVer: devDetails.browser.version,
					siteName: siteName,
					country: country,
					newLanguage: lang,
				})
					.then((response) => {
						setTokenFCM(currentToken);
					})
					.catch((error) => {
						setTokenFCM('');
					});
	
				setTokenSentToServer(true);
			}
		});

	}

	requestPermission();

	// Foreground messages
	onMessage(messaging, (payload) => {
		console.log('Foreground message received. ', payload);

		showMessageNotification({
			payload,
			receiveNotificationCallback: (mid, did) => {
				sendAdobeCampaignTrack({
					accBaseUrl: adobeCampaignTrackingBaseURL,
					mId: mid,
					dId: did,
					tcode: 1,
				});
			},
			clickNotificationCallback: (mid, did) => {
				sendAdobeCampaignTrack({
					accBaseUrl: adobeCampaignTrackingBaseURL,
					mId: mid,
					dId: did,
					tcode: 2,
				});
			},
		});
	});
};


