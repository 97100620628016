<script>
export default {
	name: 'SubTabRedeemFlight',
};
</script>

<script setup>
import RadioButton from '~/components/form/RadioButton.vue';
import SubTabBookFlight from '~/components/BookingWidget/FlightSearch/SubTabBookFlight.vue';
import { i18nGlobal } from '~/logic/i18n.js';

import AppSpinner from '~/components/AppSpinner.vue';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';

const props = defineProps({
	hideTodayDateIndicator: { type: Boolean, default: false },
	subHeaderTabDescription: { type: String, default: '' },
	subHeaderTabTooltipBody: { type: String, default: '' },
	subHeaderTabTooltipLabel: { type: String, default: '' },
	note: { type: String, default: '' },
	searchFlightCTAText: { type: String, default: i18nGlobal.t('Search flight') },
	disableGeolocation: { type: Boolean, default: false },

	ondSelection: { type: Object, default: null },
	additionalONDFilter: { type: String, default: null },
	datePicker: { type: Object, default: null },
	passengerSelection: { type: Object, default: null },
	promoCodeLabel: { type: String, default: null },
	promoCodeErrorMessage: { type: String, default: i18nGlobal.t('Conditions not met for promo code to be applied') },
	supersedePromoCode: { type: String, default: null },

	subHeaderTooltipCTATextMobileLabel: { type: String, default: '' },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	disableCalendarPickerAnimation: { type: Boolean, default: false },
	passengerSelectionQuestion: { type: String, default: '' },
	flightSearchApiUrl: { type: String, default: '' },
	cabinClassCashList: { type: Array, default: () => [] },
	cabinClassMilesList: { type: Array, default: () => [] },

	componentIdAA: { type: String, default: '' },
	togglePointsApiURL: { type: String, default: '' },

	enableMattaToggleButton: { type: Boolean, default: false },
	mattaToggleButtonLabel: { type: String, default: '' },

	hidePromoCodeField: { type: Boolean, default: false },
	
	enrichAlwaysOn: { type: Boolean, default: false },
	enablePostMattaCampaign: { type: Boolean, default: false },

	enrichAlwaysOnCountriesData: { type: Array, default: () => [] },

	loggedInMessageNote: { type: String, default: '' },
	nonLoggedInMessageNote: { type: String, default: '' },
	enableONDLocaleTranslation: { type: Boolean, default: false },
	countryVariants: { type: Array, default: () => [] },
	payByEnrichPoints: { type: Boolean, default: false },
	oneWorldLabel: { type: String, default: '' },
	malaysiaAirlinesLabel: { type: String, default: '' },
	isMalaysiaAirlinesTabHidden: { type: Boolean, default: false },
	isOneWorldTabHidden: { type: Boolean, default: false },
});

const emit = defineEmits([
	'save-search-data',
	'show-bst-overlay',
	'form-submission',
]);

const {
	isLoggedIn,
} = useAuthStore();

const activeSubOption = ref('malaysia-airlines');

const subTabMalaysiaRef = ref(null);
const subTabOneWorldRef = ref(null);

const currentSubTabRef = computed(() => {
	return activeSubOption.value === 'malaysia-airlines'
		? subTabMalaysiaRef.value
		: subTabOneWorldRef.value;
});

const restorePastSearch = async (searchData) => {
	activeSubOption.value = searchData.redeemFlightType;
	await currentSubTabRef.value?.restorePastSearch(searchData);
};
const updateLocationFromTo = async (payload) => {
	currentSubTabRef.value?.updateLocationFromTo(searchData);
};
const isExpanded = async (payload) => {
	currentSubTabRef.value?.isExpanded(searchData);
};

const selectedFlightOption = computed(() => {
	return getRedeemTabFlightOptions.value.find( (option) => option.value === activeSubOption.value) || {};
});

const getRedeemTabFlightOptions = computed(() => {
	const redeemSubTabOptions = [
		{ key: props.isMalaysiaAirlinesTabHidden, text: props.malaysiaAirlinesLabel, value: 'malaysia-airlines' },
		{ key: props.isOneWorldTabHidden, text: props.oneWorldLabel, value: 'ow-redemption' },
	];
	return redeemSubTabOptions.filter((option) => !option.key);
});

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'flight-search-tab',
	subTabName: 'redeem-flight',
});

registerQueryChange(async (query) => {
	if (!query) return;
	const newSubOption = query.suboption ?? '';
	activeSubOption.value = ['malaysia-airlines', 'ow-redemption'].includes(newSubOption)
		? query.suboption
		: 'malaysia-airlines'; // set Default value
});

defineExpose({
	restorePastSearch,
	updateLocationFromTo,
	isExpanded,
});


</script>

<template>
<div
	ref="rootEl"
	v-focus-within
	class="SubTabRedeemFlight flight-search-subcomponent"
	tabindex="-1"
	:class="{
		'isEditorMode': isEditorMode,
	}"
	@focus-within="handleRootFocusWithin"
	@blur-within="handleRootBlurWithin"
>
	<div v-if="isLoggedIn">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Flight Search</span>
		</h5>
		<div>
			<div class="flex space-x-4">
				<RadioButton
					v-model="activeSubOption"
					name="redeem-flight-options"
					:items="getRedeemTabFlightOptions"
					:layoutAttrs="{ class: '!flex' }"
					class="redeemOptions"
					@change="clearFlightDetails"
				>
				</RadioButton>
			</div>
			<!-- Content Panels -->
			<div v-if="getRedeemTabFlightOptions.length > 0">
				<SubTabBookFlight
					v-if="getRedeemTabFlightOptions.some(opt => opt.value === 'malaysia-airlines')"
					v-show="activeSubOption === 'malaysia-airlines'"
					ref="subTabMalaysiaRef"
					:activeSubOption="selectedFlightOption"
					class="pt-6"
					:payByEnrichPoints="props.payByEnrichPoints"
					:hideTodayDateIndicator="props.hideTodayDateIndicator"
					:subHeaderTabDescription="props.subHeaderTabDescription"
					:subHeaderTabTooltipBody="props.subHeaderTabTooltipBody"
					:subHeaderTabTooltipLabel="props.subHeaderTabTooltipLabel"
					:note="props.note"
					:searchFlightCTAText="props.searchFlightCTAText"
					:ondSelection="props.ondSelection"
					:additionalONDFilter="props.ondFilter"
					:datePicker="props.datePicker"
					:promoCodeLabel="props.promoCodeLabel"
					:promoCodeErrorMessage="props.promoCodeErrorMessage"
					:supersedePromoCode="props.supersedePromoCode"
					:passengerSelection="props.passengerSelection"
					:subHeaderTooltipCTATextMobileLabel="props.subHeaderTooltipCTATextMobileLabel"
					:fromQuestionMobile="props.fromQuestionMobile"
					:toQuestionMobile="props.toQuestionMobile"
					:flightSearchApiUrl="props.flightSearchApiUrl"
					:cabinClassCashList="props.cabinClassCashList"
					:cabinClassMilesList="props.cabinClassMilesList"
					:togglePointsApiURL="props.togglePointsApiURL"
					:enableMattaToggleButton="props.enableMattaToggleButton"
					:mattaToggleButtonLabel="props.mattaToggleButtonLabel"
					:hidePromoCodeField="props.hidePromoCodeField"
					:enrichAlwaysOn="props.enableFunctionality"
					:enablePostMattaCampaign="props.enablePostMattaCampaign"
					:nonLoggedInMessageNote="props.nonLoggedInMessageNote"
					:loggedInMessageNote="props.loggedInMessageNote"
					:enrichAlwaysOnCountriesData="props.enrichAlwaysOnCountriesData"
					:disableGeolocation="props.disableGeolocation"
					:enableONDLocaleTranslation="props.isEnableLocale"
					tabName="redeem-flight"
					:countryVariants="props.countryVariants"
					:useOptionsFromProp="airportOND"
					@save-search-data="$emit('save-search-data', { isBSTTab: false, ...$event })"
					@form-submission="$emit('form-submission')"
				></SubTabBookFlight>
				<SubTabBookFlight
					v-if="getRedeemTabFlightOptions.some(opt => opt.value === 'ow-redemption')"
					v-show="activeSubOption === 'ow-redemption'"
					ref="subTabOneWorldRef"
					:activeSubOption="selectedFlightOption"
					class="pt-6"
					:payByEnrichPoints="props.payByEnrichPoints"
					:hideTodayDateIndicator="props.hideTodayDateIndicator"
					:subHeaderTabDescription="props.subHeaderTabDescription"
					:subHeaderTabTooltipBody="props.subHeaderTabTooltipBody"
					:subHeaderTabTooltipLabel="props.subHeaderTabTooltipLabel"
					:note="props.note"
					:searchFlightCTAText="props.searchFlightCTAText"
					:ondSelection="props.ondSelection"
					:additionalONDFilter="props.ondFilter"
					:datePicker="props.datePicker"
					:promoCodeLabel="props.promoCodeLabel"
					:promoCodeErrorMessage="props.promoCodeErrorMessage"
					:supersedePromoCode="props.supersedePromoCode"
					:passengerSelection="props.passengerSelection"
					:subHeaderTooltipCTATextMobileLabel="props.subHeaderTooltipCTATextMobileLabel"
					:fromQuestionMobile="props.fromQuestionMobile"
					:toQuestionMobile="props.toQuestionMobile"
					:flightSearchApiUrl="props.flightSearchApiUrl"
					:cabinClassCashList="props.cabinClassCashList"
					:cabinClassMilesList="props.cabinClassMilesList"
					:togglePointsApiURL="props.togglePointsApiURL"
					:enableMattaToggleButton="props.enableMattaToggleButton"
					:mattaToggleButtonLabel="props.mattaToggleButtonLabel"
					:hidePromoCodeField="props.hidePromoCodeField"
					:enrichAlwaysOn="props.enableFunctionality"
					:enablePostMattaCampaign="props.enablePostMattaCampaign"
					:nonLoggedInMessageNote="props.nonLoggedInMessageNote"
					:loggedInMessageNote="props.loggedInMessageNote"
					:enrichAlwaysOnCountriesData="props.enrichAlwaysOnCountriesData"
					:disableGeolocation="props.disableGeolocation"
					:enableONDLocaleTranslation="props.isEnableLocale"
					tabName="redeem-flight"
					:countryVariants="props.countryVariants"
					:useOptionsFromProp="airportOND"
					@save-search-data="$emit('save-search-data', { isBSTTab: false, ...$event })"
					@form-submission="$emit('form-submission')"
				></SubTabBookFlight>
			</div>
		</div>
		
	</div>

	<Teleport v-if="isLoading" to="body">
		<div class="generic-backdrop !bg-white/80">
			<div class="flex items-center justify-center h-full w-full">
				<AppSpinner />
			</div>
		</div>
	</Teleport>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabRedeemFlight {

}
</style>
