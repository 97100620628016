import { Url as ReURL, RawUrl as ReRawURL } from 'reurl';
import { parseQuery, stringifyQuery } from '~/logic/helpers/url-query.js';

export const wrapQueryWithHTML = (text, { query = '', tag = 'mark', className = '', regexOptions = 'gi' }) => {
	if (!query) return text;
	
	return text.replace(new RegExp(query, regexOptions), (match) => {
		return `<${tag} ${className ? `class="${className}"` : ''}>${match}</${tag}>`;
	});
};


/* 
	Takes in a URL string (could be relative), and ensure that it has .html extension.
	If it already has another extension (for e.g. .pdf), this function does nothing.
	Supports query params and hash params in the given string as well.
*/
export const ensureHtmlExtension = (urlString) => {
	if (!urlString) return '';
	
	let urlObj = new ReRawURL(urlString);
	
	if (urlObj.file) {
		if (urlObj.file.endsWith('.html') || urlObj.file.includes('.')) return urlString;
		
		urlObj = urlObj.set({
			file: `${urlObj.file}.html`,
		});
		return urlObj.toString().replaceAll('%20', '+');
	} else {
		// do not have "file", it might mean URL does not have pathname, just domain only
		return urlString;
	}
};

export const isUrlMatchCurrentHostname = (urlString) => {
	if (!urlString) return false;
	let urlProcessed = urlString;
	
	if (urlProcessed.startsWith('//')) urlProcessed = `${window.location.protocol}${urlProcessed}`;
	if (urlProcessed.startsWith('/')) return true;
	if (urlProcessed.startsWith('http') || urlProcessed.startsWith('https')) {
		try {
			const urlObj = new URL(urlProcessed);
			return urlObj.hostname === window.location.hostname;
		} catch {
			return false;
		}
	}
	if (urlProcessed.startsWith('file')) return false;
	
	return true;
};

export const addRetainingQueryToUrl = (url) => {
	const RETAINING_QUERIES = ['wcmmode', 'vite', 'empty'];
	const currentRouteQuery = window.router.currentRoute.value.query;
	const queryToAdd = {};
	
	// find the overlap in current url query, and the queries we want to append ('retain') to the url agrument
	for (const q of RETAINING_QUERIES) {
		if (q in currentRouteQuery) {
			queryToAdd[q] = currentRouteQuery[q];
		}
	}

	const _reUrl = new ReRawURL(url);
	const oriQuery = _reUrl.query ? parseQuery(_reUrl.query) : null;
	
	const finalQuery = {
		...queryToAdd,
		...oriQuery,
	};
	
	let _reUrl_queryMerged = _reUrl;
	if (Object.keys(finalQuery).length > 0) {
		_reUrl_queryMerged = _reUrl.set({ query: stringifyQuery(finalQuery) });
	}
	return _reUrl_queryMerged.toString().replaceAll('%20', '+');
};

export const formatNumberWithAbbreviation = (num, digitLengthStartAbbreviate) => {
	if (!num) return '';

	const numStr = num.toString();

	// If number has digitLengthStartAbbreviate or fewer characters, return as is
	if (digitLengthStartAbbreviate && numStr.length <= digitLengthStartAbbreviate) {
		return numStr;
	}

	if (num >= 1000000000000) {
		return formatNumber(num, 1000000000000, 't');
	} else if (num >= 1000000000) {
		return formatNumber(num, 1000000000, 'b');
	} else if (num >= 1000000) {
		return formatNumber(num, 1000000, 'm');
	} else if (num >= 1000) {
		return formatNumber(num, 1000, 'k');
	}

	return Math.round(parseFloat(num)).toString();
	
};

const formatNumber = (num, divisor, suffix) => {
	const result = (num / divisor).toFixed(2);
	return result.endsWith('.00') ? `${result.slice(0, -3)}${suffix}` : `${result}${suffix}`;
};


