<script>
export default {
	name: 'SubTabManageBooking',
};
</script>

<script setup>
import FormWrapper from '~/components/form/FormWrapper.vue';
import TextField from '~/components/form/TextField.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import Tooltip from '~/components/Tooltip.vue';
import yup from 'mh-yup';
import axios from 'axios';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { boolean } from 'yup';
import mhUpgradePopup from '../../mhUpgradePopup.vue';

const props = defineProps({
	// modelValue: { type: String, default: '' },
	apiUrl: { type: String, default: '' },
	bookingReference: { type: Object, default: null },
	lastName: { type: Object, default: null },
	description: { type: String, default: '' },
	tooltipLabel: { type: String, default: '' },
	tooltipBody: { type: String, default: '' },
	buttonLabel: { type: String, default: '' },
	note: { type: String, default: '' },
	isrefXEnabled: { type: Boolean, default: false },
	enableMhUpgrade: { type: Boolean, default: false },
	upgradeImage: { type: String, default: '' },
	upgradeImageAltText: { type: String, default: '' },
	upgradeText: { type: String, default: '' },
	ctaYesBtnText: { type: String, default: '' },
	ctaNoBtnText: { type: String, default: '' },
	mhUpgradeApiUrl: { type: String, default: '' },
	componentIdAA: { type: String, default: '' },
});

const emit = defineEmits([
	// 'update:modelValue',
]);

const bookingRefValue = ref('');
const lastNameValue = ref('');
const upgradeOfferUrl = ref('');
const isPNREligibleForUpgrade = ref(false);
const isLoading = ref(false);
const { pageProperties } = useGlobalAEMState();
const country = pageProperties.value.rootCountry;
const language = pageProperties.value.rootLanguage;

const siteName = window.siteName;

const capitalizeString = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};
const regionLanguage = country && language ? `${country}-${capitalizeString(language)}` : '';

const formSubmission = async (bookingRef, lastName) => {
	if (!isPNREligibleForUpgrade.value) {
		let apiResponse = null;
		// perform API call...
		try {
			// 1st api to get response with url and payload for 2nd api
			apiResponse = await axios.get(props.apiUrl, {
				params: {
					ticketNumber: bookingRef,
					lastName: lastName,
					regionLanguage: country && language ? `${country}-${capitalizeString(language)}` : '',
					siteName: siteName,
				},
			});

			const { url, payload } = apiResponse.data;
			
			// 'RAW_DATA' doesn't required, delete it. 
			delete payload['RAW_DATA'];

			// 2nd api need to post with form submission
			// add extra property to payload object
			const generatedData = {};
			const entriesPayload = Object.entries(payload);
			for (let [index, [key, value]] of entriesPayload.entries()) {
				generatedData[index] = key;
			}
			const massageData = { ...generatedData, ...payload };
			const entriesMassageData = Object.entries(massageData);

			// create hidden form and submit
			const method = 'post';
			const form = document.createElement('form');
			form.setAttribute('method', method);
			form.setAttribute('action', url);
			
			for (let [index, [key, value]] of entriesMassageData.entries()) {
				// console.log(`apiResponse => ${index}: ${key}: ${value}`);
				const _key = parseInt(key);
				let hiddenField = document.createElement('input');
				hiddenField.setAttribute('type', 'hidden');
				hiddenField.setAttribute('name', key);
				hiddenField.setAttribute('value', value);
				form.appendChild(hiddenField);
			}
			document.body.appendChild(form);
			form.submit();

		} catch (e) {
			console.error('handleSubmitValid, ', 'Unable to fetch api', e);
		}
	}
};

const handleSubmitValid = async ({ bookingRef, lastName }, actions) => {
	try {
		if (props.enableMhUpgrade) {
			isLoading.value = true;
			const redirectUrl = `${props.apiUrl}?regionLanguage=${regionLanguage}&siteName=${siteName}`;
			const response = await axios.get(props.mhUpgradeApiUrl, {
				params: {
					ticketNumber: bookingRef,
					lastName: lastName.toUpperCase(),
					component: 'manage-booking',
					redirectUrl: redirectUrl,
				},
			});
			const { offerEligibility, offerRedirectionUrl } = response.data || {};
			isPNREligibleForUpgrade.value = offerEligibility;
			upgradeOfferUrl.value = offerRedirectionUrl;
		}
	} catch (error) {
		console.error('Error fetching PNR details:', error);
	} finally {
		formSubmission(bookingRef, lastName);
		isLoading.value = false;
	}
	
};

const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
	// perform side-effects, such as scrolling to the first error input
};

const handleNoClick = () => {
	isPNREligibleForUpgrade.value = false;
	formSubmission(bookingRefValue.value, lastNameValue.value);
};

const bookingRefValidation = computed(() => {
	let yupSchema = yup.string().nullable().matches(/^[A-Za-z0-9]*$/, props.bookingReference?.alphanumericErrorMessage);
	const {
		isMandatory,
		errorMessage,
		maxLimit,
		maxLimitErrorMessage,
	} = props.bookingReference;

	if (isMandatory) yupSchema = yupSchema.required(errorMessage);

	if (maxLimit) yupSchema = yupSchema.max(maxLimit, maxLimitErrorMessage);

	return yupSchema;
});



const lastNameValidation = computed(() => {
	let yupSchema = yup.string().nullable();
	const {
		isMandatory,
		errorMessage,
	} = props.lastName;
	if (isMandatory) yupSchema = yupSchema.required(errorMessage);

	return yupSchema;
});


</script>

<template>
<div class="SubTabManageBooking">
	<FormWrapper
		v-slot="{ errors }"
		:focusFirstErrorElOnInvalidSubmit="true"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
	>
		<div v-if="props.description" v-html-sanitize="props.description" class="mb-5"></div>
		
		<Tooltip v-if="props.tooltipLabel" class="inline-flex mb-5">
			<template #default>
				<div class="flex items-center">
					<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
					{{ props.tooltipLabel }}
				</div>
			</template>
			<template #mobile-title>
				{{ props.tooltipLabel }}
			</template>
			<template #tooltip-content>
				<div v-html-sanitize="props.tooltipBody"></div>
			</template>
		</Tooltip>

		<div class="grid-container mb-6">
			<div v-if="props.bookingReference" class="md:mb-3">
				<TextField
					v-model="bookingRefValue"
					variant="booking-widget"
					name="bookingRef"
					:ariaLabel="props.bookingReference?.label"
					:placeholder="props.bookingReference?.placeholderText"
					:validation="bookingRefValidation"
					:allowedKeys="/[A-Za-z0-9]/"
					:maxlength="props.bookingReference.maxLimit"
				>
					<template #short-description>
						<div v-if="props.bookingReference?.shortDescription && !errors?.bookingRef" v-html-sanitize="props.bookingReference?.shortDescription"></div>
					</template>
				</TextField>
			</div>
			<div class="md:mb-6">
				<TextField
					v-if="props.lastName"
					v-model="lastNameValue"
					variant="booking-widget"
					name="lastName"
					:placeholder="props.lastName?.placeholderText"
					:ariaLabel="props.lastName?.label"
					:validation="lastNameValidation"
				/>
			</div>
			<div class="relative">
				<CTAButton
					v-if="props.buttonLabel && !isLoading"
					v-aa="[
						{
							clickName: props.buttonLabel,
							clickComponentType: 'Button',
							componentName: 'SubTabManageBooking',
							componentID: props.componentIdAA,
						},
						{
							name: props.buttonLabel,
							type: 'exit',
						},
					]"
					sizeType="medium"
					:disabled="lastNameValue && bookingRefValue && Object.keys(errors).length !== 0"
					ctaSizing="fluid"
					isSubmit
				>{{ props.buttonLabel }}</CTAButton>
				<CTAButton
					v-if="isLoading"
					:ctaText="props.buttonLabel"
					:disabled="true"
					:sizeType="isMobileViewport ? 'small' : 'large'"
					ctaSizing="fluid"
				/>
				<div
					v-if="isLoading"
					class="absolute loader-position"
				>
					<img src="..\..\..\assets\loader-sm.gif" alt="" />
				</div>
			</div>
		</div>

		<div
			v-if="props.note"
			class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl"
		>
			<icon-fas-circle-info class="fill-primary-blue-base text-base" />
			<div class="text-sm">
				<p>{{ props.note }}</p>
			</div>
		</div>
	</FormWrapper>
</div>
<mhUpgradePopup
	v-if="isPNREligibleForUpgrade"
	:image="props.upgradeImage"
	:imageAltText="props.upgradeImageAltText"
	:promotionBody="props.upgradeText"
	:ctaYesButtonLabel="props.ctaYesBtnText"
	:ctaNoButtonLabel="props.ctaNoBtnText"
	:enableRefx="props.enableRefx"
	:ctaYesButtonPath="upgradeOfferUrl"
	:isManageBooking="true"
	@no-click="handleNoClick"
>
</mhUpgradePopup>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabManageBooking {
	.grid-container {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 1fr 1fr auto;
		gap: 1rem;
		@media #{var.$query-max-md} {
			display: block;
		}
	}

	.loader-position {
		width: 50px;
		top: 9%;
		left: 35%;

		@media #{var.$query-max-md} {
			top: 0%;
			left: 43%;
		}
	}
}

</style>
